<template>
    <v-row>
      <v-col>
        <v-card class="elevation-0">
          <v-card-text>
            <v-toolbar dense flat color="transparent">
              <v-icon left color="primary">mdi-scale-balance</v-icon>
              <v-toolbar-title class="primary--text">Balance de reservas activas</v-toolbar-title>
              <!-- <v-divider class="primary mx-7"></v-divider> -->
              <v-spacer></v-spacer> 
              <span class="text-h5 mr-1">Total: </span>
              <animated-number class="text-h5 primary--text" :value="totalAllBalances" :formatValue="formatToPrice" :duration="300"/>
            </v-toolbar>
            <v-data-table
              id="activeBalancesTable"
              dense
              :headers="activeBalancesHeaders"
              :items="items"
              hide-default-footer
              :items-per-page="-1"
              :loading="loading"
              @click:row="openBookingDetail"
              :sort-by="['place.typology.name']"
              :sort-desc="[false, true]"
              class="elevation-0 no-wrap-table mt-2 row-pointer">
              <template v-slot:item.hostBalance="{ item }">
                <span :class="{ 'green--text text--darken-2': item.hostBalance < 0, 'red--text': item.hostBalance > 0 }">
                  {{ Math.abs(item.hostBalance) | toCurrency }}
                </span>
              </template>
              <template v-slot:item.extraBalance="{ item }">
                <span :class="{ 'green--text text--darken-2': item.extraBalance < 0, 'red--text': item.extraBalance > 0 }">
                  {{ Math.abs(item.extraBalance) | toCurrency }}
                </span>
              </template>
              <template v-slot:item.balance="{ item }">
                <span :class="{ 'green--text text--darken-2': item.balance < 0, 'red--text': item.balance > 0 }">
                  {{ Math.abs(item.balance) | toCurrency }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog v-model="bookinDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark class="elevation-0 secondary">
              <v-toolbar-title> <strong>{{ reference }}</strong></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
              <v-btn icon dark @click="bookinDetail = false">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <BookingDetail :reference="reference"/>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </template>
  <script>
  import api from '@/api/booking'
  import AnimatedNumber from "animated-number-vue"
  import BookingDetail from '@/components/booking/BookingDetail.vue'
  import { setCurrencyFormat } from '@/utils/index'
  import { activeBalancesHeaders } from '@/constants/tableheaders'
  export default {
    components: { BookingDetail, AnimatedNumber },
    data: () => ({
      loading: true,
      activeBalancesHeaders,
      items: [],
      total: 0,
      reference: null,
      bookinDetail: false,
      totalAllBalances: 0,
    }),
    mounted() {
      this.getAllCheckinBookings()
    },
    methods: {
      async getAllCheckinBookings() {
        try {
          this.loading = true
          const { data: { data: bookings, total, totalAllBalances }} = await api.getActiveBalancesByRooms({ status: 'checked_in' })
          console.log('bookings', bookings)
          this.totalAllBalances = totalAllBalances
          this.items = bookings.filter(v => !v.isVirtual).map(item => this.formatDataTable(item))
          this.total = total
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      formatDataTable(item) {
        item.user.name = `${item.user.name.split(' ')[0]} ${item.user.lastName.split(' ')[0].length < 4 ? item.user.lastName : item.user.lastName.split(' ')[0]}`
        // item.hostBalance = setCurrencyFormat(item.hostBalance)
        // item.extraBalance = setCurrencyFormat(item.extraBalance)
        // item.balance = setCurrencyFormat(item.balance)
        return item
      },
      openBookingDetail(row) {
        console.log('clicked row', row)
        this.reference = row.ref
        this.bookinDetail = true
      },
      formatToPrice(value) {
        return setCurrencyFormat(value)
      },
    },
  }
</script>
<style>
#activeBalancesTable td:nth-child(6) {
  border-right: thin solid rgba(0, 0, 0, 0.12)
}
#activeBalancesTable td:nth-child(3) {
  border-right: thin solid rgba(0, 0, 0, 0.12)
}
#activeBalancesTable td:nth-child(9) {
  border-right: thin solid rgba(0, 0, 0, 0.12)
}
</style>
    
    