<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          General <v-icon>mdi-chevron-right</v-icon> Reviews de {{ types[type] }}
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#servcieTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="`review-${types[type]}`" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <ServiceOrder ref="rewviewTable" :type="type" :title="types[type]"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import ServiceOrder from '@/components/reports/review/Table.vue'
import api from '@/api/review'
export default {
  components: { ServiceOrder },
  data: () => ({
    dataExcel: [],
    type: '',
    types: { community: 'Comunidad', employee: 'Empleados', product: 'Productos', service: 'Servicios', task: 'Tareas' }
  }),
  created() {
  },
  methods: {
    async beforeDownload() {
      const query = {
        dateStart: this.$refs.rewviewTable.dateStart,
        dateEnd: this.$refs.rewviewTable.dateEnd
      }
      const { data } = await api.fetch(this.type, query)
      console.log(data)
      this.dataExcel = data.data.map(item => {
        let review = ''
        if (item.type == 'community') review = item.relatedData?.name + ' ' + item.relatedData?.lastName
        else if (item.type == 'employee') review = item.relatedData?.name + ' ' + item.relatedData?.lastName
        else if (item.type == 'service') review = item.relatedData?.service.name + ' - ' + item.relatedData.employee?.name + ' ' + item.relatedData.employee?.lastName
        return {
          'Review': review,
          'Cliente': item.user?.name + ' ' + item.user?.lastName,
          'Valores': item.values.reduce((a,b) => a + `\n${b.name}:${b.value}`,'').substring(1),
          'Fecha': moment(item.createdAt).format('DD/MM/yyyy hh:mm a')
        }
      })
    },
    openDrawer() {
      this.$refs.rewviewTable.openDrawer()
    },
  },
  watch: {
    '$route.params.type': {
      immediate: true,
      handler(newType) {
        this.type = newType
      }
    }
  }
}
</script>