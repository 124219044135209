<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-book</v-icon>
            <v-toolbar-title class="primary--text">Ocupacion</v-toolbar-title>
            <v-divider class="primary mx-7"></v-divider>
          </v-toolbar>
          <v-data-table
            :loading="loading"
            id="ocupacionTable"
            :headers="ocupacionHeaders"
            :items="items"
            :items-per-page="12"
            class="elevation-0 mt-2"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment'
  import api from '@/api/report'
  import { ocupacionHeaders } from '@/constants/tableheaders'
  import { setCurrencyFormat } from '@/utils/index'

  export default {
    props: {
      year: String,
    },
    data: () => ({
      ocupacionHeaders,
      items: [],
      loading: false,
    }),
    mounted() {
      this.getOcupacion(moment().format('YYYY'))
    },
    methods: {
      async getOcupacion(year) {
        try {
          this.loading = true
          this.items = []
          const { data } = await api.getOcupacion({ year })
          const ocupacion = data.map(v => {
            v.percentage = v.percentage.toFixed(2)
            v.total = setCurrencyFormat(v.total)
            return v
          })
          this.items = ocupacion
          console.log('ocupacion', data)
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({
            type: 'error',
            group: 'noti',
            title: 'Error con comunicacion con el servidor.',
            text: 'No se pudo establecer comunicacion con servidor.',
          })
        }
      },
    },
    watch: {
      year(newVal) {
        this.getOcupacion(newVal)
      },
    },
  }
</script>
