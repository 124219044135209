<template>
  <v-row justify="center" align="center">
    <v-col cols="12" md="10" justify="center" align="center">
    <LineChartGenerator
      :chart-options="chartOptions"
      :data="chartDataLine"
      :styles="styles"
    />
    </v-col>
  </v-row>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  PointElement,
  LinearScale,
} from 'chart.js'
import { Line as LineChartGenerator } from 'vue-chartjs'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale, LineElement,)
export default {
  name: 'ScatterChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'scatter-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 10
    },
    height: {
      type: Number,
      default: 10
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    businessSelected: { type: String, default: () => null },
    initDate: { type: String, default: () => null },
    endDate: { type: String, default: () => null },
    chartDataLine: { type: Object, default: () => {} },
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false
    },
    
  }),
}
</script>
