<template>
    <v-container fluid>
      <v-toolbar dense color="transparent" flat>
        <v-toolbar-title class="grey--text text--darken-2">
          <v-subheader>
            Gerencia <v-icon>mdi-chevron-right</v-icon> Recaudo
          </v-subheader>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="openDrawer()">
          <v-icon>mdi-filter-cog</v-icon>
        </v-btn>
        <v-btn v-print="'#bookingTable'" icon>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
          <v-btn icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel>
      </v-toolbar>
      <v-toolbar dense flat color="transparent">
        <v-icon left color="primary">mdi-finance</v-icon>
        <v-toolbar-title class="primary--text">Recaudo</v-toolbar-title>
        <v-divider class="primary mx-7"></v-divider>
        <v-icon left color="primary">mdi-finance</v-icon>
        <v-toolbar-title class="primary--text">Recaudo</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col class="text-center">
                <RecaudoChart :filters="filters" ref="recaudoChart"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <BookingTable :filters="filters" ref="recaudoBookingTable"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col>
                <NumberCards :filters="filters" ref="recaudoNumberCards"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-8">
                <NacionalidadesChart :filters="filters" ref="recaudoNacionalidadesChart"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="450" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Tipo de reserva</v-subheader>
              <v-select :loading="loading" prepend-icon="mdi-ghost-outline" v-model="filters.isVirtual" :items="filters.types" hide-details dense placeholder="Todas"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Cliente</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-account" v-model="filters.usersSelected" @update:search-input="onSearchUser" :items="filters.users" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="John Doe" multiple clearable chips deletable-chips small-chips :hint="'John doe'">
                <template v-slot:item="{ item }">
                  <v-list-item-avatar color="grey lighten-3">
                    <v-img :src="item.photo"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.documentID }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Status</v-subheader>
              <v-select style="min-height: auto" :loading="loading" prepend-icon="mdi-traffic-light" v-model="filters.statusSelected" :items="filters.status" hide-details dense multiple chips deletable-chips placeholder="Check In" small-chips>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar>
                    <v-icon :color="item.value">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Tipologia
                <v-spacer></v-spacer>
                <!-- <span>
                  <v-checkbox :loading="loading" small v-model="filters.byRoom" :label="`Habitacion`" hide-details dense></v-checkbox>
                </span> -->
              </v-subheader>
              <v-autocomplete v-if="!filters.byRoom" :loading="loading" prepend-icon="mdi-bed-empty" v-model="filters.typology" :items="filters.typologies" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Suite" multiple clearable chips deletable-chips small-chips></v-autocomplete>
              <v-autocomplete v-else :loading="loading" @update:search-input="onSearchPlace" prepend-icon="mdi-bed-empty" v-model="filters.place" :items="filters.places" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="714" multiple clearable chips deletable-chips small-chips></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Mes
              </v-subheader>
              <v-dialog ref="modalCheckin" v-model="modalCheckin" :return-value.sync="filters.monthSelected" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="filters.monthSelected" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`09/2024`"></v-text-field>
                </template>
                <v-date-picker type="month" v-model="filters.monthSelected" @input="$refs.modalCheckin.save(filters.monthSelected)"></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="height: 64px;"></div>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getReportData" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-container>
  </template>
  
  <script>
  import moment from 'moment'
  import BookingTable from '@/components/gerencia/recaudo/BookingTable'
  import NumberCards from '@/components/gerencia/recaudo/NumberCards'
  import RecaudoChart from '@/components/gerencia/recaudo/RecaudoChart'
  import NacionalidadesChart from '@/components/gerencia/recaudo/NacionalidadesChart'
  import apiTypology from '@/api/typology'
  import apiUser from '@/api/user'
  import { status } from '@/constants/status'

  export default {
    components: { BookingTable, NumberCards, RecaudoChart, NacionalidadesChart },
    data: () => ({
      dataExcel: [],
      drawerRight: false,
      loading: false,
      modalCheckin: false,
      modalCheckout: false,
      modalCreatedAt: false,
      filters: {
        typologies: [],
        typology: null,
        usersSelected: [],
        users: [],
        places: [],
        place: null,
        checkinRange: false,
        checkoutRange: false,
        statusSelected: ['checked_in', 'checked_out', 'confirmed', 'no_confirmed'],
        status,
        byRoom: false,
        monthSelected: moment.utc().startOf('month').format('YYYY-MM'),
        checkin: moment.utc().startOf('month').format('YYYY-MM-DD'),
        checkout: moment.utc().endOf('month').format('YYYY-MM-DD'),
        types: [
          { text: 'Todas', value: null },
          { text: 'Reales', value: false },
          { text: 'Virtuales', value: true },
        ],
        isVirtual: false,
        usersSelected: [],
      }
    }),
    mounted() {
      this.getTypologies()
      // this.getPlaces()
      this.getUsers()
    },
    methods: {
      beforeDownload() {
        this.dataExcel = this.$refs.bookingTable.items
      },
      openDrawer() {
        this.drawerRight = true
      },
      onSearchUser(search) {
        this.getUsers(search)
      },
      onSearchPlace(search) {
        // this.getPlaces(search)
      },
      async getTypologies() {
      try {
          const { data: { data: typologies } } = await apiTypology.fetchAll()
          console.log('typologies', typologies)
          this.filters.typologies = typologies.map(v => ({ text: `${v.name}`, value: v._id }))
          console.log('typologies', this.typologies)
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      async getUsers(search) {
        try {
          this.loading = true
          const { data: { data: users } } = await apiUser.table({ itemsPerPage: 10, page: 1, search })
          // console.log('user note', search)
          console.log('users 111', users)
          const auxSelecteds = this.filters.users.filter(v => this.filters.usersSelected.some(e => v.value === e))
          // console.log('auxSelecteds', auxSelecteds)
          this.filters.users = users.map(v => ({ text: `${v.name} ${v.lastName}`, value: v._id, email: v.email, data: v }))
          this.filters.users = [...this.filters.users, ...auxSelecteds]
          console.log('users', users)
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      getReportData() {
        this.$refs.recaudoBookingTable.getBookings(this.filters)
        this.$refs.recaudoNumberCards.getData(this.filters)
        this.$refs.recaudoChart.getData(this.filters)
        this.$refs.recaudoNacionalidadesChart.getData(this.filters)
      },
    },
    watch: {
      'filters.monthSelected': function (newVal) {
        this.filters.checkin = moment.utc(newVal).startOf('month').format('YYYY-MM-DD')
        this.filters.checkout = moment.utc(newVal).endOf('month').format('YYYY-MM-DD')
      },
    },
  }
  </script>