import axios from '../index'

export default {
  getSales(params) {
    return axios.get('/api/v1/report/closed-order', { params })
  },
  getSalesGroup(params) {
    return axios.get('/api/v1/report/group-order', { params })
  },
  getPopularity(params) {
    return axios.get('/api/v1/report/popularity', { params })
  },
  getGift(params) {
    return axios.get('/api/v1/report/gift', { params })
  },
  getDetailSales(params) {
    return axios.get('/api/v1/report/detail-sale', { params })
  },
  getPayment(params) {
    return axios.get('/api/v1/report/payment', { params })
  },
  getSalesApp(params) {
    return axios.get('/api/v1/report/sales-app', { params })
  },
  getSalesGeneral(params) {
    return axios.get('/api/v1/report/sales-general', { params })
  },
  getPayGeneral(params) {
    return axios.get('/api/v1/report/pay-general', { params })
  },
  getMomentDay(params) {
    return axios.get('/api/v1/report/day-moment', { params })
  },
  getSoldProducts(params) {
    return axios.get('/api/v1/report/sold-products', { params })
  },
  getGrossMargin(params) {
    return axios.get('/api/v1/report/detail-products', { params })
  },
  getStatusTime(params) {
    return axios.get('/api/v1/report/status-time', { params })
  },
}