import axios from 'axios'
const { VUE_APP_API_MASTER } = process.env
export default {
  useTimeReport(params) {
    return axios.get(`${VUE_APP_API_MASTER}/api/v1/use/time/resume/fetch`, { params })
  },
  useTimeGroupReport(params) {
    return axios.get(`${VUE_APP_API_MASTER}/api/v1/use/time/resume/group/fetch`, { params })
  },
  getUserAPPInfo(masterID) {
    return axios.get(`${VUE_APP_API_MASTER}/api/v1/client/${masterID}`)
  }
}