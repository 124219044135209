<template>
  <v-navigation-drawer app v-model="drawer" :mini-variant.sync="mini" dark expand-on-hover :permanent="permanent">
    <vue-custom-scrollbar :style="{ height: windowHeight + 'px' }"  :settings="settings">
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="user.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ user.name }}
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-title>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item @click.stop="dialog = true">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><span>Cerrar sesion</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense>
        <template v-for="item in items">
          <v-list-item v-if="item.link" :key="item.title" link :to="item.link">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :key="`${item.title}-else`" color="grey lighten-2" :value="false" :prepend-icon="item.icon"> 
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item v-for="i in item.items" :key="i.title" link :to="i.link">
              <v-list-item-icon>
                <!-- <span class="mx-auto">{{ i.title.charAt(0) }}</span> -->
                <v-badge dark color="secondary" bottom overlap :content="i.title.charAt(0)">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ i.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            ¿Desear cerrar sesion en este dispositivo?
          </v-card-title>
          <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="logout()">
              Continuar
            </v-btn>
            <v-btn color="cancelar" text @click="dialog = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </vue-custom-scrollbar>
  </v-navigation-drawer>
</template>

<script>
const { VUE_APP_FILE_URL } = process.env 
import asideLinks from '@/constants/asideLinks'
const defaultAvatar = 'https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
export default {
  components: {
    vueCustomScrollbar
  },
  data: () => ({
    settings: {
      suppressScrollY: false,
      suppressScrollX: false,
      wheelPropagation: false
    },
    windowHeight: window.innerHeight,
    permanent: false,
    drawer: false,
    items: asideLinks,
    mini: true,
    dialog: false,
    user: {
      name: '',
      email: '',
      avatar: defaultAvatar,
    },
  }),
  mounted() {
    const currentPath = this.$route.path
    if (currentPath !== '/auth/login') { this.drawer = true }
    this.setUserInfo()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.windowHeight = window.innerHeight
    },
    setUserInfo() {
      const currentPath = this.$route.path
      const user = JSON.parse(localStorage.getItem('user'))
      // console.log('user', user)
      if (!user && currentPath !== '/auth/login') { return this.$router.push('/auth/login') }
      this.user.name = user?.name
      this.user.email = user?.email
      this.user.avatar = user?.avatar ? `${VUE_APP_FILE_URL}/lg/${user.avatar}` : defaultAvatar
    },
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      this.$router.push('/auth/login')
      this.drawer = false
      this.dialog = false
    },
  },
  watch: {
    '$route': function(newVal) {
      // console.log('newVal', newVal)
      if (newVal.name === 'login') {
        this.drawer = false
      } else {
        this.setUserInfo()
        this.drawer = true
      }
    },
    drawer(newVal) {
      this.permanent = newVal
    },
  },
}
</script>