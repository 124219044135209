<template>
  <div>
    <v-row>
      <v-col>
        <v-card :loading="loading ? 'primary' : false" class="text-center elevation-0">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon class="pr-2">mdi-calendar</v-icon> Fecha
          </v-card-title>
          <!-- <hr class="px-10 mx-10 primary"> -->
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black">{{ new Date() | toDate }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card :loading="loading" class="text-center elevation-0" color="grey lighten-5">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon color="green darken-1" class="pr-2">mdi-arrow-bottom-left-thick</v-icon> Llegadas
          </v-card-title>
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black green--text text--darken-1">{{ dashboard.incomings.length }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card :loading="loading" class="text-center elevation-0" color="grey lighten-5">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon color="red darken-1" class="pr-2">mdi-arrow-top-right-thick</v-icon> Salidas
          </v-card-title>
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black red--text text--darken-1">{{ dashboard.leaves.length }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card :loading="loading" class="text-center elevation-0" color="grey lighten-5">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon class="pr-2 primary--text">mdi-home</v-icon> En casa
          </v-card-title>
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black primary--text">{{ dashboard.inHouse.length }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading" class="text-center elevation-0" color="grey lighten-5">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon class="pr-2">mdi-percent</v-icon> Porcentaje de ocupacion
          </v-card-title>
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black">% {{ dashboard.occupiedPercentage }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card :loading="loading" class="text-center elevation-0" color="grey lighten-5">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon color="red darken-1" class="pr-2">mdi-bed</v-icon> Habitaciones ocupadas
          </v-card-title>
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black red--text text--darken-1">{{ dashboard.occupiedRooms }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card :loading="loading" class="text-center elevation-0" color="grey lighten-5">
          <v-card-title class="justify-center grey--text text--darken-1">
            <v-icon color="green darken-1" class="pr-2">mdi-bed-empty</v-icon> Habitaciones disponibles
          </v-card-title>
          <v-card-text class="">
            <span v-if="!loading" class="text-h5 font-weight-black green--text text--darken-1">{{ dashboard.freeRooms }}</span>
            <v-skeleton-loader v-else style="height: 30px" type="image"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="elevation-0">
          <v-tabs fixed-tabs v-model="tab">
            <v-tab><v-icon class="pr-2 primary--text">mdi-home</v-icon>En Casa</v-tab>
            <v-tab><v-icon color="green darken-1" class="pr-2">mdi-arrow-bottom-left-thick</v-icon>Llegadas</v-tab>
            <v-tab><v-icon color="red darken-1" class="pr-2">mdi-arrow-top-right-thick</v-icon>Salidas</v-tab>
            <v-tab><v-icon class="pr-2">mdi-clock-outline</v-icon>Actividad hoy</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- En casa -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-toolbar color="transparent" flat>
                    <v-toolbar-title class="grey--text text--darken-2">
                      <v-subheader>
                        Reservas en hospedaje actualmente
                      </v-subheader>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field style="max-width: 300px" v-model="searchInHouse" hide-details label="Search" placeholder="Buscar por palabra clave"></v-text-field>
                    <v-btn v-print="'#inHouseTable'" icon>
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <download-excel :name="'Reservas en hospedaje'" :data="dashboard.inHouse">
                      <v-btn icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-toolbar>
                  <v-data-table
                    id="inHouseTable"
                    @click:row="openBookingDetail"
                    :search="searchInHouse"
                    dense
                    :loading="loading"
                    :headers="dashboarHeaders"
                    :items="dashboard.inHouse"
                    :items-per-page="-1"
                    class="elevation-0 row-pointer no-wrap-table">
                    <template v-slot:item.status="{ item }">
                      {{ item.status | toStatus }} 
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- llegadas -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-toolbar color="transparent" flat>
                    <v-toolbar-title class="grey--text text--darken-2">
                      <v-subheader>
                        Reservas que se esperan para el dia de hoy
                      </v-subheader>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field style="max-width: 300px" v-model="searchIncomings" hide-details label="Search" placeholder="Buscar por palabra clave"></v-text-field>
                    <v-btn v-print="'#incomingsTable'" icon>
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <download-excel :name="'Reservas por llegar'" :data="dashboard.incomings">
                      <v-btn icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-toolbar>
                  <v-data-table
                    id="incomingsTable"
                    @click:row="openBookingDetail"
                    :search="searchIncomings"
                    dense
                    :loading="loading"
                    :headers="dashboarHeaders"
                    :items="dashboard.incomings"
                    :items-per-page="-1"
                    class="elevation-0 row-pointer no-wrap-table">
                    <template v-slot:item.status="{ item }">
                      {{ item.status | toStatus }} 
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- Salidas -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-toolbar color="transparent" flat>
                    <v-toolbar-title class="grey--text text--darken-2">
                      <v-subheader>
                        Reservas que finalizan hoy
                      </v-subheader>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field style="max-width: 300px" v-model="searchLeaves" hide-details label="Search" placeholder="Buscar por palabra clave"></v-text-field>
                    <v-btn v-print="'#leavesTable'" icon>
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <download-excel :name="'Reservas terminadas'" :data="dashboard.leaves">
                      <v-btn icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-toolbar>
                  <v-data-table
                    id="leavesTable"
                    dense
                    @click:row="openBookingDetail"
                    :search="searchLeaves"
                    :loading="loading"
                    :headers="dashboarHeaders"
                    :items="dashboard.leaves"
                    :items-per-page="-1"
                    class="elevation-0 row-pointer no-wrap-table">
                    <template v-slot:item.status="{ item }">
                      {{ item.status | toStatus }} 
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- Actividad -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-toolbar color="transparent" flat>
                    <v-toolbar-title class="grey--text text--darken-2">
                      <v-subheader>
                        Actividad de reservas creadas hoy
                      </v-subheader>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field style="max-width: 300px" v-model="searchActivity" hide-details label="Search" placeholder="Buscar por palabra clave"></v-text-field>
                    <v-btn v-print="'#activityTable'" icon>
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <download-excel :name="'Reservas terminadas'" :data="dashboard.createdToday">
                      <v-btn icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-toolbar>
                  <v-data-table
                    id="activityTable"
                    @click:row="openBookingDetail"
                    dense
                    :search="searchActivity"
                    :loading="loading"
                    :headers="dashboarHeaders"
                    :items="dashboard.createdToday"
                    :items-per-page="-1"
                    class="elevation-0 row-pointer no-wrap-table">
                    <template v-slot:item.status="{ item }">
                      {{ item.status | toStatus }} 
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-dialog v-model="bookinDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark class="elevation-0 secondary">
              <v-toolbar-title> <strong>{{ reference }}</strong></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
              <v-btn icon dark @click="bookinDetail = false">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <BookingDetail :reference="reference"/>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import BookingDetail from '@/components/booking/BookingDetail.vue'
import moment from 'moment'
import { dashboarHeaders } from '@/constants/tableheaders'
import api from '@/api/report'
export default {
  components: { BookingDetail },
  data: () => ({
    loading: true,
    bookinDetail: false,
    searchInHouse: '',
    searchIncomings: '',
    searchLeaves: '',
    searchActivity: '',
    tab: null,
    dashboard : {
      incomings: [],
      inHouse: [],
      leaves: [],
      createdToday: [],
      totalRooms: 0,
      occupiedPercentage: 0,
      occupiedRooms: 0,
      freeRooms: 0,
    },
    dashboarHeaders,
    reference: null,
  }),
  mounted() {
    this.getDashboard()
  },
  methods: {
    async getDashboard() {
      try {
        this.loading = true
        const { data: dashboard } = await api.getDashboard()
        this.dashboard.occupiedPercentage = dashboard.occupiedPercentage.toFixed(2)
        this.dashboard.incomings = dashboard.incomings.map(item => this.formatDataTable(item))
        this.dashboard.inHouse = dashboard.inHouse.map(item => this.formatDataTable(item))
        this.dashboard.leaves = dashboard.leaves.map(item => this.formatDataTable(item))
        this.dashboard.createdToday = dashboard.createdToday.map(item => this.formatDataTable(item))
        this.dashboard.occupiedRooms = dashboard.occupiedRooms
        this.dashboard.freeRooms = dashboard.freeRooms
        // console.log('dashboard', dashboard)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({
          type: 'error',
          group: 'noti',
          title: 'Error con comunicacion con el servidor.',
          text: 'No se pudo establecer comunicacion con servidor.',
        })
      }
    },
    formatDataTable(item) {
      item.wayContactName = item.wayContactName ? item.wayContactName?.length < 12 ? item.wayContactName : `${item.wayContactName?.substring(0, 11)}...` : ''
      item.user.name = `${item.user.name.split(' ')[0]} ${item.user.lastName.split(' ')[0].length < 4 ? item.user.lastName : item.user.lastName.split(' ')[0]}`
      item.dateStart = moment.utc(item.dateStart).format('DD/MM/YYYY')
      item.dateEnd = moment.utc(item.dateEnd).format('DD/MM/YYYY')
      return item
    },
    openBookingDetail(row) {
      console.log('clicked row', row)
      this.reference = row.ref
      this.bookinDetail = true
    },
  },
}
</script>