<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-data-table
          id="bookingTable"
          dense
          :headers="PosOrderGroupHeaders"
          :items="dataOrder"
          :options.sync="options"
          :server-items-length="total"
          :loading="loadingData"
          :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
          class="elevation-0 row-pointer no-wrap-table">
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status | toStatus }} 
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { setCurrencyFormat } from '@/utils/index'
import apiOrder from '@/api/externos/orderPos'
import { PosOrderGroupHeaders } from '@/constants/tableheaders'

export default {
  components: {},
  props: { filters: Object },
  data: () => ({
    loading: true,
    loadingData: true,
    items: [],
    total: 0,
    options: {
      sortBy: ['total'],
    },

    PosOrderGroupHeaders,
    dataOrder: [],
  }),
  methods: {
    async getDataTable() {
      try {
        let dates = {}
        this.dataOrder = []
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
        else if (this.filters.rangeDate.length == 1)
          dates = {
            initDate: this.filters.rangeDate[0],
            endDate: this.filters.rangeDate[0],
          }
        const direction = sortDesc[0] ? "asc" : "desc"
        let field = sortBy[0]
        if (sortBy[0] == "client") field = "userInfo.name"
        else if (sortBy[0] == "total") field = "totalAcum"
        const query = {
          sort:
            sortBy.length > 0
              ? [
                  {
                    direction: direction,
                    field: field,
                    sortField: field,
                  },
                ]
              : null,
          page: page,
          per_page: itemsPerPage,
          initDate: dates.initDate,
          endDate: dates.endDate,
        }
        this.loadingData = true
        const {data: dataOrder} = await apiOrder.getSalesGroup(query)
        this.dataOrder = dataOrder.data
        this.dataOrder = this.dataOrder.map(j=>{
          return {
            client: `${j?.userInfo?.name?.toUpperCase()} ${j?.userInfo?.lastName?.toUpperCase()}`,
            total: setCurrencyFormat(j.totalAcum),
          }
        })
        this.total = dataOrder.links.pagination.total
        this.loadingData = false
      } catch (error) {
        console.log(error, "error")
        this.loadingData = false
      }
    },
  },
  watch: {
    options: {
      handler () {
        this.getDataTable()
      },
      deep: true,
    },
  },
}
</script>

