<template>
    <v-container fluid>
      <v-toolbar dense color="transparent" flat>
        <v-toolbar-title class="grey--text text--darken-2">
          <v-subheader>
            Gerencia <v-icon>mdi-chevron-right</v-icon> Reporte POS
          </v-subheader>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="openDrawer()">
          <v-icon>mdi-filter-cog</v-icon>
        </v-btn>
        <!-- <v-btn v-print="'#bookingTable'" icon>
          <v-icon>mdi-printer</v-icon>
        </v-btn> -->
        <download-excel :name="'Margin'" :before-generate="beforeDownload" :data="dataExcel">
          <v-btn icon :loading="loading">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> 
      </v-toolbar>
      <v-toolbar dense flat color="transparent">
        <v-icon left color="primary">mdi-finance</v-icon>
        <v-toolbar-title class="primary--text">POS</v-toolbar-title>
        <v-divider class="primary mx-7"></v-divider>
        <v-icon left color="primary">mdi-finance</v-icon>
        <v-toolbar-title class="primary--text">POS</v-toolbar-title>
      </v-toolbar>
     
      <div class="text-center primary--text"><span>{{filters.rangeDate[0]}} - {{filters.rangeDate[1]}}</span></div>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div>
              <h5>Cantidad total: {{totalItems}}</h5>
              <v-spacer></v-spacer>
              <h5>Ingreso total: {{totalIncome}}</h5>
              <v-spacer></v-spacer>
              <h5>CMV (%): {{totalCMV}}%</h5>
              <v-spacer></v-spacer>
              <h5>Margen Bruto total: {{totalSoldMargin}}</h5>
            </div>
            <ProductTable :filters="filters" :loading="loading" :loadingData="loadingData" :chartData="chartData" :changeOption="changeOption" :businessSelected="businessSelected" :total="total" :optionsTable="optionsTable" :itemsData="itemsData" ref="productTable"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <SoldProductsChart :filters="filters" ref="SoldProductsChart"/>
          </v-col>
          <v-col cols="7" style="max-height: 40vh;">
            <ProductNumbersChart :filters="filters" :totalMargin="totalMargin" ref="productNumbersChart"/>
          </v-col>
        </v-row>
      </v-container>
      <!-- Filters Menu -->
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="450" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Rango de fecha
              </v-subheader>
              <v-dialog ref="modalDateStart" v-model="modalDateStart" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="filters.rangeDate" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`26/09/2024`"></v-text-field>
                </template>
                <v-date-picker range v-model="filters.rangeDate" @input="handleDateInput"></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Negocio</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-store" v-model="businessSelected" @update:search-input="onSearchBusiness" :items="business" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Negocio" multiple clearable chips deletable-chips small-chips>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Cortesía</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-gift" :items="optionGift" v-model="filters.gift" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Cortesía" clearable>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getReportData" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text @click="clean">Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-container>
  </template>
  
  <script>
  import moment from 'moment'
  import ProductTable from '@/components/gerencia/inventory/ProductTable'
  import SoldProductsChart from '@/components/gerencia/inventory/SoldProductsChart.vue'
  import ProductNumbersChart from '@/components/gerencia/inventory/ProductNumbersChart'
  import apiBusiness from '@/api/business'
  import apiOrder from '@/api/externos/orderPos'
  import { setCurrencyFormat } from '@/utils/index'
import { createLogger } from 'vuex'

  export default {
    components: { ProductTable, SoldProductsChart, ProductNumbersChart },
    data: () => ({
      dataExcel: [],
      drawerRight: false,
      loading: false,
      loadingData: true,
      modalDateStart: false,
      modalDateEnd: false,
      modalCreatedAt: false,
      filters: {
        rangeDate: [moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),], 
        gift: null
      },
      businessSelected: [],
      business: [],
      optionGift: [
        { text: 'SÍ', value: true },
        { text: 'No', value: false }
      ],
      itemsData: [],
      totalMargin: [],
      total: 0,
      optionsTable: {
        sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10
      },
      chartData: {
        labels: [],
        datasets: [
          { 
            labels: "Ingreso por unidad de negocio",
            data: [],
          },
        ],
      },
      totalItems: 0,
      totalIncome: 0,
      totalCMV: 0,
      totalSoldMargin: 0,
    }),
    methods: {
      handleDateInput(dates) {
      if (dates && dates.length === 2) this.modalDateStart = false
      },
      async beforeDownload() {
        try {
          let dates = {}
          this.loading = true
          if (this.filters.rangeDate.length > 1)
              dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
              sort: null,
              page: 1,
              per_page: -1,
              initDate: dates.initDate,
              endDate: dates.endDate,
              gift: this.filters.gift,
              business: this.businessSelected
            }
            const {data: {data: dataOrder}} = await apiOrder.getGrossMargin(query)
            const dataExcel = dataOrder.data.data.map(e=>{
              return {
                "Negocio": e.business.name,
                "Item": e.name,
                "Cantidad": e.totalQty,
                "Ingreso real": e.totalPrice.toFixed(2).replace('.', ','),
                "CMV (%)": e.totalPrice > 0 ? ( e.cost * 100 / e.totalPrice).toFixed(2).replace('.', ',') : 100,
                "Margen Bruto ($)": e.grossMargin ? e.grossMargin.toFixed(2).replace('.', ',') : 0
              }
            })
          this.dataExcel = dataExcel
          this.loading = false
        }
        catch(err) {
           this.loading = false
        }
      },
      openDrawer() {
        this.drawerRight = true
      },
      getReportData() {
        const monthCurrent = moment(new Date()).format('YYYY-MM')
        if (!this.filters.rangeDate[0] || !this.filters.rangeDate[1] || moment(this.filters.rangeDate[0]).format('YYYY-MM') === monthCurrent || moment(this.filters.rangeDate[1]).format('YYYY-MM') === monthCurrent ) 
        return this.$notify({ type: 'error', group: 'noti', title: 'Error', text: 'Debe seleccionar un rango de fecha válido' })
        this.getDataTable()
        this.$refs.SoldProductsChart.getDataMetrics(this.filters)
      },

      clean() {
        this.filters.rangeDate = []
      },

      async getBusiness(search) {
      try {
        this.loading = true
        let { data: { data: business } } = await apiBusiness.table({ itemsPerPage: 500, page: 1, search })
        business = business.filter(e=> e.business && !e.business.service)
        const auxSelecteds = this.business.filter(v => this.businessSelected.some(e => v.value === e))
        this.business = business.map(v => ({ text: v.name, value: v._id }))
        this.business = [...this.business, ...auxSelecteds]
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
      },

      async getDataTable() {
        try {
          let dates = {}
          this.dataOrder = []
          const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1]  }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const direction = sortDesc[0] ? "asc" : "desc"
          let field = sortBy[0]
          if (sortBy[0] == "client") field = "userInfo.name"
          else if (sortBy[0] == "total") field = "totalAcum"
          const query = {
            sort:
              sortBy.length > 0
                ? [
                    {
                      direction: direction,
                      field: field,
                      sortField: field,
                    },
                  ]
                : null,
            page: page,
            per_page: itemsPerPage,
            initDate: dates.initDate,
            endDate: dates.endDate,
            gift: this.filters.gift,
            business: this.businessSelected
          }
          this.loadingData = true
          const {data: {data: dataOrder}} = await apiOrder.getGrossMargin(query)
          //console.log(dataOrder,'dataOrder');
          this.itemsData = dataOrder.data.data.map(j=>{
            const perc = j.cost ? '100%' : '--'
            return {
              ...j,
              totalReal: setCurrencyFormat(j.totalPrice),
              totalGrossMargin: j.grossMargin ? setCurrencyFormat(j.grossMargin) : '--',
              cmv: j.totalPrice > 0 ? `${( j.cost * 100 / j.totalPrice).toFixed(2)}%` : perc,
            }
          })
          this.getDataBar(dataOrder.dataBusiness) 
          this.total = dataOrder.data.total
          this.totalMargin = dataOrder.totalMargin
          this.totalItems = dataOrder.dataBusiness.reduce((acum, value) => acum + value.totalQty, 0);
          this.totalIncome = dataOrder.dataBusiness.filter(e=>e.total > 0)
          .reduce((acum, value) => acum + value.total, 0);
          this.totalSoldMargin = this.totalMargin.reduce((acum, value) => acum + value.total, 0);
          this.totalCMV = (Number(this.totalIncome) > 0 ? (Number(this.totalIncome) - Number(this.totalSoldMargin)) * 100 / Number(this.totalIncome) : 100).toFixed(2)
          this.totalIncome = setCurrencyFormat(this.totalIncome)
          this.totalSoldMargin = setCurrencyFormat(this.totalSoldMargin)
          this.loadingData = false
        } catch (error) {
          console.log(error, "error")
          this.loadingData = false
        }
      },
    changeOption(options) {
      this.optionsTable = options
    },
    getDataBar(data) {
      const filterData = data.filter(e=>e.total>0)
      this.chartData = {
        labels: filterData.map(e=>e.business.name),
        datasets: [{
          label: 'Ingreso por unidad de negocio',
          backgroundColor: '#a88e68',
          data: filterData.map(e=>e.total)
        }]
      }
      this.loading = false
    },
    onSearchBusiness(search) {
      this.getBusiness(search)
    },
    },
    watch: {
      optionsTable: {
      handler () {
        this.getDataTable()
      },
      deep: true,
    },
    },
  }
</script>
<style>
#productNumbersChart {
  margin: auto !important;
}
</style>