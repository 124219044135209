<template>
  <v-card class="elevation-0" :loading="loading">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular >
                <animated-number v-else class="text-h4 primary--text" :value="totalExtraOrders" :formatValue="formatToPrice" :duration="300"/>
                <div>Ingresos de cargos a la habitacion</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="averageLodgingDays" :formatValue="formatToPrice" :duration="300"/>
                <div>Extensiones a la reserva</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="averageRates" :formatValue="formatToPrice" :duration="300"/>
                <div>Promedio de arriendo</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="averageNights" :formatValue="formatToPrice" :duration="300"/>
                <div>Promedio de noches de arriendo</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="totalRates" :formatValue="formatToPrice" :duration="300"/>
                <div>Ingresos de arrendamientos</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="totalBookings" :formatValue="formatToPrice" :duration="300"/>
                <div>Total de reservas</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import millify from 'millify'
import AnimatedNumber from "animated-number-vue"
import moment from 'moment'
import api from '@/api/booking'

export default {
  components: { AnimatedNumber },
  props: {
    filters: Object,
  },
  data: () => ({
    loading: true,
    totalExtraOrders: 0,
    totalBookings: 0,
    totalRates: 0,
    averageLodgingDays: 0,
    averageNights: 0,
    averageRates: 0,
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData(filters) {
      this.loading = true
      try {
        if (!filters) { filters = this.filters }
        const query = {
          ...this.options,
          isVirtual: filters.isVirtual,
          user: filters.usersSelected,
          status: filters.statusSelected,
          typology: filters.typology,
          place: filters.place,
          select: 'rates',
          populate: 'orders',
          dateStart: filters.checkinRange 
            ? filters.checkin ? [moment(filters.checkin[0]).format('YYYY-MM-DD'), moment(filters.checkin[1]).format('YYYY-MM-DD')] : null
            : filters.checkin ? moment(filters.checkin).format('YYYY-MM-DD') : null,
          dateEnd: filters.checkoutRange 
            ? filters.checkout ? [moment(filters.checkout[0]).format('YYYY-MM-DD'), moment(filters.checkout[1]).format('YYYY-MM-DD')] : null
            : filters.checkout ? moment(filters.checkout).format('YYYY-MM-DD') : null,
          createdAt: filters.createdAtRange 
            ? filters.createdAt ? [moment(filters.createdAt[0]).format('YYYY-MM-DD'), moment(filters.createdAt[1]).format('YYYY-MM-DD')] : null
            : filters.createdAt ? moment(filters.createdAt).format('YYYY-MM-DD') : null,
        }
        const { data } = await api.getRecaudoStatistics(query)
        this.totalBookings = data.total
        this.totalExtraOrders = data.totalExtraOrders
        this.totalRates = data.totalRates
        this.averageNights = data.averageNights
        this.averageRates = data.averageRates
        this.loading = false
        console.log('DATAAAAA', data)
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formatToPrice(value) {
      return millify(value);
    },
  },
}
</script>