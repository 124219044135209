<template>
  <v-row>
    <v-col>
      <v-toolbar flat color="transparent">
        <v-toolbar-title class="primary--text">Precios por noche</v-toolbar-title>
        <v-spacer></v-spacer> 
      </v-toolbar>
      <v-divider class="primary"></v-divider>
      <v-data-table
        id="ratesTable"
        dense
        :headers="ratesHeaders"
        :items="items"
        :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
        :options.sync="options"
        class="elevation-0 mt-5">
      </v-data-table>
      <v-divider class="primary mt-5"></v-divider>
    </v-col>
  </v-row>  
</template>
<script>
import moment from 'moment'
import { setCurrencyFormat } from '@/utils/index'
import { ratesHeaders } from '@/constants/tableheaders'

export default {
  props: {
    rates: Array,
  },
  data: () => ({
    ratesHeaders,
    items: [],
    options: {},
  }),
  mounted() {
    this.items = this.rates.map(data => this.formatDataTable(data))
  },
  methods: {
    formatDataTable(data) {
      data.date = moment.utc(data.date).format('DD/MM/YYYY')
      data.totalRate = setCurrencyFormat(data.totalRate)
      data.closed = data.closed ? 'Cerrado' : 'Abierto'
      return data
    },
  },
  watch: {
    rates(newVal) {
      if (Array.isArray(newVal)) {
        this.items = newVal.map(data => this.formatDataTable(data))
      }
    }
  },
}
</script>