<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Movimientos <v-icon>mdi-chevron-right</v-icon> Reporte de ordenes
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#bookingTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Ordenes'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <OrderTable ref="orderTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderTable from '@/components/booking/balance/OrderTable'
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    async beforeDownload() {
      this.dataExcel = await this.$refs.orderTable.getAllItems()
      this.dataExcel = this.dataExcel.map(item => this.formatExcelData(item))
    },
    formatExcelData(item) {
      item.items = item.items.length
      item.typology = item?.booking?.place?.typology?.name
      item.place = item?.booking?.place?.name
      item.booking = item?.booking?.ref
      item.employee = item.employee
      item.business = item.business.name
      delete item.__v
      delete item.updatedAt
      delete item.deleted
      delete item.isCanceled
      return item
    },
    openDrawer() {
      this.$refs.orderTable.openDrawer()
    },
  },
}
</script>