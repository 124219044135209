<template>
  <div>
    <v-sheet tile height="64" class="d-flex">
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title class="text-uppercase primary--text" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ types.find(t => t.value == type).text }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list v-for="t in types" :key="t.value">
            <v-list-item @click="type = t.value">
              <v-list-item-title>{{ t.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :type="type"
        :events="events"
        event-overlap-mode="column"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        @click:more="viewDay"
        @click:date="viewDay"
        @click:event="showEvent"
      ></v-calendar>
    </v-sheet>
    <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar :color="selectedEvent.color" dark>
          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-list class="transparent">
            <v-list-item class="px-0" v-if="selectedEvent.place">
              <v-list-item-title>{{ selectedEvent.place }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-0" v-if="selectedEvent.client">
              <v-list-item-title>{{ selectedEvent.client.name }} {{ selectedEvent.client.lastName }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-0">
              <v-list-item-title>{{ formatTime(selectedEvent.start) }} a {{ formatTime(selectedEvent.end) }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <pre>{{ selectedEvent?.hola }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="secondary" @click="selectedOpen = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
import apiTask from '@/api/task'
import apiPlaceReservation from '@/api/placeReservation'
export default {
  data: () => ({
    dateStart: '',
    dateEnd: '',
    type: 'month',
    types: [{ value: 'month', text: 'Mes' }, { value: 'week', text: 'Semana' }, { value: 'day', text: 'Día' }],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    events: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  created () {
  },
  methods: {
    async getEvents ({ start, end }) {
      const events = []
      this.dateStart = start.date
      this.dateEnd = end.date
      const reservations = await this.loadEvent(start.date, end.date)
      if (reservations.events.length > 0) {
        reservations.events.forEach((element) => {
          console.log(moment(element.dateStart).utc())
          events.push({
            name: element.title,
            place: element.place.name,
            start: moment(element.dateStart).utc().format('YYYY-MM-DD HH:mm'),
            end: moment(element.dateEnd).utc().utc().format('YYYY-MM-DD HH:mm'),
            color: 'deep-orange',
            timed: true,
            note: element.description,
            _id: element._id,
          })
        })
      }
      if (reservations.spaces.length > 0) {
        reservations.spaces.forEach((element) => {
          events.push({
            name: element.place.name,
            start: moment(element.dateStart).utc().format('YYYY-MM-DD HH:mm'),
            end: moment(element.dateEnd).utc().format('YYYY-MM-DD HH:mm'),
            color: 'brown',
            client: element.user,
            timed: true,
            _id: element._id,
          })
        })
      }
      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    async loadEvent (dateStart, dateEnd) {
      const { data: task} = await apiTask.fetch({ dateStart, dateEnd })
      console.log('==============================================')
      console.log(task)
      const { data } = await apiPlaceReservation.list({ dateStart, dateEnd })
      console.log('hola', data)
      return data
    },
    viewDay ({ date }) {
      this.value = date
      this.type = 'day'
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    formatTime (date) {
      return moment(date).format('hh:mm a')
    }
  },
  watch: {
  }
}
</script>

<style scoped>
</style>