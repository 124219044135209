<template>
  <Bar ref="bar" v-if="loaded" :data="chartData" :options="chartOptions" :style="myStyles" />
</template>

<script>
import apiMaster from '@/api/externos/master'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export default {
  components: { Bar },
  props: ['date', 'tabWidth'],
  data: () => ({
    loaded: false,
		chartData: {
      labels: [],
      datasets: []
		},
		chartOptions: {
          indexAxis: 'y',
		}
	}),
  computed: {
    myStyles () {
      return {
        ... this.tabWidth ? { width: `${this.tabWidth}px !important` }: {},
        position: 'relative'
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loaded = false
      const { data } = await apiMaster.useTimeReport({ date: `${this.date} 00:00` })
      this.chartData = {
        labels: data.map(x => `${x.user.name} ${x.user.lastName}`),
        datasets: [{
          label: 'Horas',
          backgroundColor: '#A88E68',
          data: data.map(x => (x.time / 3600))
        }],
        chartOptions: {
          indexAxis: 'y',
        },
      }
      this.loaded = true
    }
  },
  watch: {
    date () {
      this.loadData()
    }
  }
}
</script>
