<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Housekeeping <v-icon>mdi-chevron-right</v-icon> Reporte de lugares y tareas
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small class="elevation-0" color="primary" @click="openDrawer()">
        Leyenda
      </v-btn>
    </v-toolbar>
    <v-row class="mt-2">
      <v-col>
        <TaskCalendar ref="TaskCalendar"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TaskCalendar from '@/components/reports/housekeeping/TaskCalendar.vue'
export default {
  components: { TaskCalendar },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    openDrawer() {
      this.$refs.TaskCalendar.openDrawer()
    }
  },
}
</script>