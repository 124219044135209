<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-book</v-icon>
            <v-toolbar-title class="primary--text">Ventas</v-toolbar-title>
            <v-spacer></v-spacer> 
            <span class="text-h5 mr-1">Total: </span>
            <animated-number class="text-h5 primary--text" :value="totalCalculate" :formatValue="formatToPrice" :duration="300"/>
          </v-toolbar>
          <!-- <v-divider class="primary"></v-divider> -->
          <v-data-table
            id="bookingTable"
            dense
            :headers="bookingHeaders"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            @click:row="openBookingDetail"
            :loading="loadingData"
            :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
            class="elevation-0 row-pointer no-wrap-table mt-2">
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status | toStatus }} 
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-dialog v-model="bookinDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark class="elevation-0 secondary">
            <v-toolbar-title> <strong>{{ reference }}</strong></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn icon dark @click="bookinDetail = false">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <BookingDetail :reference="reference"/>
        </v-card>
      </v-dialog>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app >
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Tipo de reserva</v-subheader>
              <v-select :loading="loading" prepend-icon="mdi-ghost-outline" v-model="isVirtual" :items="types" hide-details dense placeholder="Todas"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Cliente</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-account" v-model="usersSelected" @update:search-input="onSearchUser" :items="users" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="John Doe" multiple clearable chips deletable-chips small-chips :hint="'John doe'">
                <template v-slot:item="{ item }">
                  <v-list-item-avatar color="grey lighten-3">
                    <v-img :src="item.photo"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.documentID }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Status</v-subheader>
              <v-select style="min-height: auto" :loading="loading" prepend-icon="mdi-traffic-light" v-model="statusSelected" :items="status" hide-details dense multiple chips deletable-chips placeholder="Check In" small-chips>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar>
                    <v-icon :color="item.value">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Tipologia
                <v-spacer></v-spacer>
                <span>
                  <v-checkbox :loading="loading" small v-model="byRoom" :label="`Habitacion`" hide-details dense></v-checkbox>
                </span>
              </v-subheader>
              <v-autocomplete v-if="!byRoom" :loading="loading" prepend-icon="mdi-bed-empty" v-model="typology" :items="typologies" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Suite" multiple clearable chips deletable-chips small-chips></v-autocomplete>
              <v-autocomplete v-else :loading="loading" @update:search-input="onSearchPlace" prepend-icon="mdi-bed-empty" v-model="place" :items="places" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="714" multiple clearable chips deletable-chips small-chips></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Fecha inicial
                <v-spacer></v-spacer>
                <span>
                  <v-checkbox  :loading="loading" v-model="checkinRange" :label="`Rango`" hide-details dense></v-checkbox>
                </span>
              </v-subheader>
              <v-dialog ref="modalCheckin" v-model="modalCheckin" :return-value.sync="checkin" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="checkin" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`${checkinRange ? '26/09/2024 ~ 01/12/2024' : '26/09/2024' }`"></v-text-field>
                </template>
                <v-date-picker v-if="!checkinRange" v-model="checkin" @input="$refs.modalCheckin.save(checkin)"></v-date-picker>
                <v-date-picker v-else v-model="checkin" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalCheckin = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.modalCheckin.save(checkin)">Aceptar</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Fecha final
                <v-spacer></v-spacer>
                <span>
                  <v-checkbox :loading="loading" small v-model="checkoutRange" :label="`Rango`" hide-details dense></v-checkbox>
                </span>
              </v-subheader>
              <v-dialog ref="modalCheckout" v-model="modalCheckout" :return-value.sync="checkout" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="checkout" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`${checkoutRange ? '26/09/2024 ~ 01/12/2024' : '26/09/2024' }`"></v-text-field>
                </template>
                <v-date-picker v-if="!checkoutRange" v-model="checkout" @input="$refs.modalCheckout.save(checkout)"></v-date-picker>
                <v-date-picker v-else v-model="checkout" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalCheckout = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.modalCheckout.save(checkout)">Aceptar</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Fecha de creacion
                <v-spacer></v-spacer>
                <span>
                  <v-checkbox :loading="loading" small v-model="createdAtRange" :label="`Rango`" hide-details dense></v-checkbox>
                </span>
              </v-subheader>
              <v-dialog ref="modalCreatedAt" v-model="modalCreatedAt" :return-value.sync="createdAt" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar-badge" v-model="createdAt" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`${createdAtRange ? '26/09/2024 ~ 01/12/2024' : '26/09/2024' }`"></v-text-field>
                </template>
                <v-date-picker v-if="!createdAtRange" v-model="createdAt" @input="$refs.modalCreatedAt.save(createdAt)"></v-date-picker>
                <v-date-picker v-else v-model="createdAt" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalCreatedAt = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.modalCreatedAt.save(createdAt)">Aceptar</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="height: 64px;"></div>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getBookings" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>
  
  <script>
  import AnimatedNumber from "animated-number-vue"
  import { setCurrencyFormat } from '@/utils/index'
  import BookingDetail from '@/components/booking/BookingDetail.vue'
  import moment from 'moment'
  import api from '@/api/report'
  import apiUser from '@/api/user'
  import apiPlace from '@/api/place'
  import apiTypology from '@/api/typology'
  import { bookingHeaders } from '@/constants/tableheaders'
  import { status } from '@/constants/status'
  
  export default {
    components: { BookingDetail, AnimatedNumber },
    props: {
      setTotal: Function,
    },
    data: () => ({
      loadingData: true,
      loading: true,
      typologies: [],
      typology: null,
      modalCheckin: false,
      modalCheckout: false,
      modalCreatedAt: false,
      usersSelected: [],
      users: [],
      places: [],
      place: null,
      statusSelected: null,
      status,
      checkinRange: false,
      checkoutRange: false,
      createdAtRange: false,
      byRoom: false,
      bookinDetail: false,
      checkin: moment.utc().startOf('month').format('YYYY-MM-DD'),
      checkout: moment.utc().endOf('month').format('YYYY-MM-DD'),
      createdAt: null,
      total: 0,
      items: [],
      options: {},
      bookingHeaders,
      reference: null,
      drawerRight: false,
      totalCalculate: 0,
      types: [
        { text: 'Todas', value: null },
        { text: 'Reales', value: false },
        { text: 'Virtuales', value: true },
      ],
      isVirtual: null,
    }),
    mounted() {
      this.getTypologies()
      this.getPlaces()
      this.getUsers()
    },
    methods: {
      async getPlaces(search) {
        try {
          this.loading = true
          const { data: { data: places } } = await apiPlace.table({ itemsPerPage: 10, page: 1, search })
          // console.log('places', places)
          this.places = places.map(v => ({ text: `${v.name} - ${v.typology.name}`, value: v._id }))
          // console.log('places', this.places)
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      async getTypologies() {
        try {
          const { data: { data: typologies } } = await apiTypology.fetchAll()
          // console.log('typologies', typologies)
          this.typologies = typologies.map(v => ({ text: `${v.name}`, value: v._id }))
          // console.log('typologies', this.typologies)
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      async getUsers(search) {
        try {
          this.loading = true
          const { data: { data: users } } = await apiUser.table({ itemsPerPage: 10, page: 1, search })
          // console.log('user note', search)
          const auxSelecteds = this.users.filter(v => this.usersSelected.some(e => v.value === e))
          // console.log('auxSelecteds', auxSelecteds)
          this.users = users.map(v => ({ text: `${v.name} ${v.lastName}`, value: v._id, email: v.email, data: v }))
          this.users = [...this.users, ...auxSelecteds]
          // console.log('users', users)
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      async getBookings() {
        try {
          this.loadingData = true
          const query = {
            ...this.options,
            isVirtual: this.isVirtual,
            user: this.usersSelected,
            status: this.statusSelected,
            typology: this.typology,
            place: this.place,
            dateStart: this.checkinRange 
              ? this.checkin ? [moment(this.checkin[0]).format('YYYY-MM-DD'), moment(this.checkin[1]).format('YYYY-MM-DD')] : null
              : this.checkin ? moment(this.checkin).format('YYYY-MM-DD') : null,
            dateEnd: this.checkoutRange 
              ? this.checkout ? [moment(this.checkout[0]).format('YYYY-MM-DD'), moment(this.checkout[1]).format('YYYY-MM-DD')] : null
              : this.checkout ? moment(this.checkout).format('YYYY-MM-DD') : null,
            createdAt: this.createdAtRange 
              ? this.createdAt ? [moment(this.createdAt[0]).format('YYYY-MM-DD'), moment(this.createdAt[1]).format('YYYY-MM-DD')] : null
              : this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : null,
          }
          const { data: { total, data: bookings, totalCalculate} } = await api.getBookingSales(query)
          // console.log('bookings', bookings)
          // console.log('totalCalculate', totalCalculate)
          this.setTotal(totalCalculate)
          this.totalCalculate = totalCalculate
          this.total = total
          this.items = bookings.map(item => this.formatDataTable(item))
          this.loadingData = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      formatDataTable(item) {
        item.wayContactName = item.wayContactName ? item.wayContactName?.length < 12 ? item.wayContactName : `${item.wayContactName?.substring(0, 11)}...` : ''
        item.place.typology.name = item.place.typology.name ? item.place.typology.name?.length < 15 ? item.place.typology.name : `${item.place.typology.name?.substring(0, 11)}...` : ''
        item.place.name = item.place.name ? item.place.name?.length < 15 ? item.place.name : `${item.place.name?.substring(0, 11)}...` : ''
        item.user.name = `${item.user.name.split(' ')[0]} ${item.user.lastName.split(' ')[0].length < 4 ? item.user.lastName : item.user.lastName.split(' ')[0]}`
        item.dateStart = moment.utc(item.dateStart).format('DD/MM/YYYY')
        item.dateEnd = moment.utc(item.dateEnd).format('DD/MM/YYYY')
        item.createdAt = moment.utc(item.createdAt).format('DD/MM/YYYY')
        item.isVirtual = item.isVirtual ? 'SI' : 'NO'
        item.total = setCurrencyFormat(item.total)
        return item
      },
      openBookingDetail(row) {
        console.log('clicked row', row)
        this.reference = row.ref
        this.bookinDetail = true
      },
      openDrawer() {
        this.drawerRight = true
      },
      onSearchUser(search) {
        this.getUsers(search)
      },
      onSearchPlace(search) {
        this.getPlaces(search)
      },
      formatToPrice(value) {
        return setCurrencyFormat(value)
      },
      async getForExcell() {
        try {
          this.loadingData = true
          const query = {
            isVirtual: this.isVirtual,
            user: this.usersSelected,
            status: this.statusSelected,
            typology: this.typology,
            place: this.place,
            dateStart: this.checkinRange 
              ? this.checkin ? [moment(this.checkin[0]).format('YYYY-MM-DD'), moment(this.checkin[1]).format('YYYY-MM-DD')] : null
              : this.checkin ? moment(this.checkin).format('YYYY-MM-DD') : null,
            dateEnd: this.checkoutRange 
              ? this.checkout ? [moment(this.checkout[0]).format('YYYY-MM-DD'), moment(this.checkout[1]).format('YYYY-MM-DD')] : null
              : this.checkout ? moment(this.checkout).format('YYYY-MM-DD') : null,
            createdAt: this.createdAtRange 
              ? this.createdAt ? [moment(this.createdAt[0]).format('YYYY-MM-DD'), moment(this.createdAt[1]).format('YYYY-MM-DD')] : null
              : this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : null,
          }
          const { data: { total, data: bookings, totalCalculate} } = await api.getBookingSales(query)
          // console.log('bookings', bookings)
          // console.log('totalCalculate', totalCalculate)
          this.loadingData = false
          return bookings.map(item => this.formatDataTable(item))
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
    },
    watch: {
      options: {
        handler () {
          this.getBookings()
        },
        deep: true,
      },
      checkinRange() {
        this.checkin = null
      },
      checkoutRange() {
        this.checkout = null
      },
      createdAtRange() {
        this.createdAt = null
      },
      byRoom() {
        this.place = null
        this.typology = null
      }
    },
  }
  </script>
  
  