<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Movimientos <v-icon>mdi-chevron-right</v-icon> Reporte de pagos
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#payTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Pagos'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <PayTable ref="payTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PayTable from '@/components/booking/balance/PayTable'
export default {
  components: { PayTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    async beforeDownload() {
      this.dataExcel = await this.$refs.payTable.getAllItems()
      this.dataExcel = this.dataExcel.map(item => this.formatExcelData(item))
    },
    formatExcelData(item) {
      item.employee = JSON.stringify(item.employee)
      return item
    },
    openDrawer() {
      this.$refs.payTable.openDrawer()
    },
  },
}
</script>