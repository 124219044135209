
<template>
<div class="text-center">
  <v-card :loading="loading" class="elevation-0" style="height: 100%" max-height="300px">
    <Bar id="recaudo-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
</div>
</template>
  
<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    components: { Bar },
    props: {
      styles: {
      type: Object,
      default: () => {}
    },
      timeRange: { type: Object, default: {} }
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: [
          { 
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    mounted() {
      this.getData()
    },
    watch: {
      timeRange: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      getData() {
        this.chartData = {
          labels:  ["0-10 min", "11-30 min", "31-40 min", "+40 min"],
          datasets: [{
            label: 'Intervalo de tiempo (Abierto - Entregado)',
            backgroundColor: ["#D5D5D5", "#7DC398", "#FFDD91", "#F27372"],
            data: [this.timeRange.range1, this.timeRange.range2, this.timeRange.range3, this.timeRange.range4]
          }]
        }
        this.loading = false
      }
    },
  }
  </script>