<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-toolbar v-if="!reference" dense flat color="transparent">
            <v-icon left color="primary">mdi-credit-card</v-icon>
            <v-toolbar-title class="primary--text">Pagos</v-toolbar-title>
            <v-divider v-if="!reference" class="primary mx-7"></v-divider>
          </v-toolbar>
          <v-data-table
            :loading="loading"
            id="payTable"
            dense
            :headers="payHeaders"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
            class="elevation-0 no-wrap-table mt-5">
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
      <v-toolbar dark class="elevation-0 secondary">
        <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-filter-cog</v-icon>
      </v-toolbar>
      <v-container>
        <!-- <v-row>
          <v-col class="pb-0">
            <v-subheader class="secondary--text" style="height: auto;">Tipo de reserva</v-subheader>
            <v-select :loading="loading" prepend-icon="mdi-ghost-outline" v-model="isVirtual" :items="types" hide-details dense placeholder="Todas"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-subheader class="secondary--text" style="height: auto;">Tienda</v-subheader>
            <v-autocomplete :loading="loading" @update:search-input="onSearchBusiness" prepend-icon="mdi-store" v-model="business" :items="businessArray" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Restaurante" multiple clearable chips deletable-chips small-chips></v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="pb-0">
            <v-subheader class="secondary--text" style="height: auto;">
              Fecha de creacion
              <v-spacer></v-spacer>
              <span>
                <v-checkbox :loading="loading" small v-model="createdAtRange" :label="`Rango`" hide-details dense></v-checkbox>
              </span>
            </v-subheader>
            <v-dialog ref="modalCreatedAt" v-model="modalCreatedAt" :return-value.sync="createdAt" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :loading="loading" prepend-icon="mdi-calendar-badge" v-model="createdAt" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`${createdAtRange ? '26/09/2024 ~ 01/12/2024' : '26/09/2024' }`"></v-text-field>
              </template>
              <v-date-picker v-if="!createdAtRange" v-model="createdAt" @input="$refs.modalCreatedAt.save(createdAt)"></v-date-picker>
              <v-date-picker v-else v-model="createdAt" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalCreatedAt = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.modalCreatedAt.save(createdAt)">Aceptar</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div style="height: 64px;"></div>
          </v-col>
        </v-row>
        <v-footer fixed padless>
          <v-row>
            <v-col class="text-center" cols="12" >
              <v-toolbar  class="elevation-0" color="grey lighten-5">
                <v-btn @click="getPays" class="elevation-0 primary--text" text>Aplicar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="elevation-0" text>Limpiar</v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-footer>
      </v-container>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { setCurrencyFormat } from '@/utils/index'
import moment from 'moment'
import api from '@/api/pay'
import { payHeaders } from '@/constants/tableheaders'
export default {
  data: () => ({
    loading: true,
    payHeaders,
    items: [],
    options: {},
    total: 0,
    drawerRight: false,
    createdAt: null,
    modalCreatedAt: false,
    createdAtRange: false,
  }),
  props: {
    reference: String,
  },
  mounted() {
    this.getPays()
  },
  methods: {
    openDrawer() {
      this.drawerRight = true
    },
    async getPays() {
      try {
        this.loading = true
        const query = {
          ...this.options,
          createdAt: this.createdAtRange 
            ? this.createdAt ? [moment(this.createdAt[0]).format('YYYY-MM-DD'), moment(this.createdAt[1]).format('YYYY-MM-DD')] : null
            : this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : null,
        }
        const { data: { data: pays, total } } = await api.getPaysByRef(this.reference, query)
        this.items = pays.map(item => this.formDataTable(item))
        this.total = total
        // console.log('data ', orders)
        // console.log('total ', total)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formDataTable(item) {
      item.isForLodging = item.isForLodging ? 'HOSPEDAJE' : 'EXTRA'
      item.createdAt = moment.utc(item.createdAt).format('DD/MM/YYYY')
      item.paymentMethod = item.paymentMethod?.toUpperCase()
      item.type = item.type?.toUpperCase()
      item.amount = setCurrencyFormat(item.amount)
      item.employee = item.employee ? `${item.employee.name.split(' ')[0]} ${item.employee.lastName.split(' ')[0]}` : ''
      return item
    },
    async getAllItems() {
      try {
        this.loading = true
        const query = {
          createdAt: this.createdAtRange 
            ? this.createdAt ? [moment(this.createdAt[0]).format('YYYY-MM-DD'), moment(this.createdAt[1]).format('YYYY-MM-DD')] : null
            : this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : null,
        }
        const { data: { data: pays } } = await api.getPaysByRef(this.reference, query)
        this.loading = false
        return pays
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
  },
  watch: {
    reference(newVal) {
      this.getPays()
    },
    options: {
      deep: true,
      handler() {
        this.getPays()
      },
    },
  },
}
</script>