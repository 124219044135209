<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="elevation-0">
          <v-card-text>
            <v-row>
              <v-col>
                <v-subheader v-if="!loading">
                  Orden creada el {{ order?.createdAt | toDateUTC }}
                  <br/>
                  Ultima actualizacion {{ order?.updatedAt | toDateUTC }}
                </v-subheader>
                <v-skeleton-loader v-else style="height: 30px; width: 200px;" type="image"></v-skeleton-loader>
              </v-col>
              <v-col class="text-right">
                <span v-if="!loading" class="text-h5 primary--text">
                  <!-- <span class="text-caption">(Funcionalidad no terminada)</span> -->
                  No pagada
                </span>
                <v-skeleton-loader v-else style="height: 30px; width: 100px; float: right;" type="image"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-toolbar flat color="transparent">
                  <v-toolbar-title class="secondary--text">Detalle de cobro</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon color="secondary">mdi-cash</v-icon>
                </v-toolbar>
                <v-divider class="primary"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card flat>
                  <v-card-actions class="d-flex">
                    <div style="height: 100%; width: 100%;">
                      <div v-if="loading" >
                        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                      </div>
                      <table v-else style="width:100%; min-height: 10vh" class="booking-info-table">
                        <tr>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Total</strong> {{ order?.total | toCurrency }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Subtotal</strong> {{ order?.subtotal | toCurrency }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Descuento</strong> {{ order?.discount | toCurrency }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Moneda</strong> {{ order?.currency }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Es Dividida</strong> {{ order?.isSecondary ? 'SI' : 'NO' }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Es por hospedaje</strong> {{ order?.isForLodging ? 'SI' : 'NO' }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Virtual</strong> {{ order?.isVirtual ? 'SI' : 'NO' }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Propina</strong> {{ order?.tipInclude ? 'SI' : 'NO' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Responable</strong> {{ order?.employee?.name.split(' ')[0] }} {{ order?.employee?.lastName.split(' ')[0] }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Tienda</strong> {{ order?.business?.name }}
                            </span>
                          </td>
                        </tr>
                      </table>
                      <v-data-table
                        :loading="loading"
                        id="orderTable"
                        :headers="orderItemHeaders"
                        :items="items"
                        hide-default-footer
                        class="elevation-0 no-wrap-table mt-5">
                      </v-data-table>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-toolbar flat color="transparent">
                  <v-toolbar-title class="secondary--text">Resumen de reserva</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon color="secondary">mdi-book</v-icon>
                </v-toolbar>
                <v-divider class="primary"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card flat>
                  <v-card-actions class="d-flex">
                    <div style="height: 100%; width: 100%;">
                      <div v-if="loading" >
                        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                      </div>
                      <table v-else style="width:100%; min-height: 10vh" class="booking-info-table">
                        <tr>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Reserva</strong> {{ order?.booking?.ref | toCurrency }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Habitacion</strong> {{ order?.booking?.place?.typology?.name }} {{ order?.booking?.place?.name }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Cliente</strong> {{ order?.booking?.user?.name?.split(' ')[0] }} {{ order?.booking?.user?.lastName?.split(' ')[0] }}
                            </span>
                          </td>
                          <td class="px-2">
                            <span>
                              <strong class="grey--text text--darken-1">Status</strong> {{ order?.booking?.status | toStatus }}
                            </span>
                          </td>
                        </tr>
                      </table>
                      <!-- <v-btn block class="primary--text elevation-0" @click="openBookingDetail">
                        Ver reserva en detalle...
                      </v-btn> -->
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog v-model="bookingDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark class="elevation-0 secondary">
              <v-toolbar-title> <strong>{{ reference }}</strong></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
              <v-btn icon dark @click="bookingDetail = false">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <!-- <BookingDetail :reference="reference"/> -->
          </v-card>
        </v-dialog>
        <!-- <pre>
          {{ order }}
        </pre> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { setCurrencyFormat } from '@/utils/index'
// import BookingDetail from '@/components/booking/BookingDetail.vue'
import { orderItemHeaders } from '@/constants/tableheaders'
import api from '@/api/order'
export default {
  // components: { BookingDetail },
  props: {
    _id: String,
  },
  data: () => ({
    bookingDetail: false,
    orderItemHeaders,
    order: {},
    loading: true,
    items: [],
    reference: null,
  }),
  mounted() {
    this.getOrder()
  },
  methods: {
    async getOrder() {
      try {
        this.loading = true
        const { data: order } = await api.getOneOrder(this._id)
        console.log('order', order)
        this.order = order
        this.items = order.items.map(item => this.formatDataTable(item))
        this.loading = false
      } catch (error) {
        this.loadingData = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formatDataTable(item) {
      item.type = `${item.type.code} (%${item.type.value})`
      item.isFree = item.isFree ? 'SI' : 'NO'
      item.priceSubtotal = setCurrencyFormat(item.priceSubtotal)
      item.priceTotal = setCurrencyFormat(item.priceTotal)
      return item
    },
    openBookingDetail() {
      this.reference = this.order?.booking?.ref
      this.bookingDetail = true
    },
  },
  watch: {
    _id() {
      this.getOrder()  
    },
  },
}
</script>