<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          General <v-icon>mdi-chevron-right</v-icon> Reporte de ocupación
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select style="max-width: 200px" v-model="year" :items="years" label="Año" hide-details></v-select>
      <v-btn v-print="'#ocupacionTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <ocupacion-table-vue ref="ocupacionTable" :year="year"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import OcupacionTableVue from '@/components/reports/booking/OcupacionTable.vue'
export default {
  components: { OcupacionTableVue },
  data: () => ({
    dataExcel: [],
    years: [],
    year: moment().format('YYYY')
  }),
  created() {
    this.setYears()
  },
  methods: {
    beforeDownload() {
      this.dataExcel = this.$refs.ocupacionTable.items
    },
    setYears() {
      const startYear = '2020'
      let nextYear = '2021'
      const endYear = moment(`${this.year}-01-01`).add(10, 'year').format('YYYY')
      this.years.push(startYear)
      this.years.push(nextYear)
      do {
        nextYear = moment(`${nextYear}-01-01`).add(1, 'year').format('YYYY')
        this.years.push(nextYear)
      } while (nextYear !== endYear);
    },
  },
}
</script>