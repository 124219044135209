<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          General <v-icon>mdi-chevron-right</v-icon> Reporte de balances activos
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-title class="text-h4 primary--text">{{ total | toCurrency }}</v-toolbar-title> -->
      <!-- <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn> -->
      <v-btn v-print="'#activeBalancesTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Balances activos'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <ActiveBalances ref="activeBalancesTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActiveBalances from '@/components/gerencia/balance/ActiveBalancesTable.vue'
export default {
  components: { ActiveBalances },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    beforeDownload() {
      this.dataExcel = this.$refs.activeBalancesTable.items
    },
  },
}
</script>