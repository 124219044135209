<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-cash-plus</v-icon>
            <v-toolbar-title class="primary--text">Propinas</v-toolbar-title>
            <div class="ml-2 primary--text mt-1">
              {{ computedDateStartFormatted }} -
              {{ computedDateEndFormatted }}
            </div>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details style="max-width: 200px;"></v-text-field>
          </v-toolbar>
          <v-divider class="primary"></v-divider>
          <v-data-table id="tipTable" dense :headers="tipHeaders" :items="items" :options.sync="options"
            :server-items-length="total" :loading="loadingData"
            :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
            class="elevation-0 row-pointer no-wrap-table mt-2">
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | toCurrency }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-cash-plus</v-icon>
            <v-toolbar-title class="primary--text">Consolidado</v-toolbar-title>
            <div class="ml-2 primary--text mt-1">
              {{ computedDateStartFormatted }} -
              {{ computedDateEndFormatted }}
            </div>
          </v-toolbar>
          <v-divider class="primary"></v-divider>
          <v-data-table id="tipTable" dense :headers="tipHeaders2" :items="items2" :options.sync="options2" :loading="loadingData"
            :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
            class="elevation-0 row-pointer no-wrap-table mt-2" hide-default-footer>
            <template v-slot:[`item.employee`]="{ item }">
              {{ item.employee.name }}
              {{ item.employee.lastName }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.total | toCurrency }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateStartFormatted"
                    label="Fecha inicial"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateStart" @input="menu1 = false" locale="es" :max="dateEnd"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateEndFormatted"
                    label="Fecha inicial"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateEnd" @input="menu2 = false" locale="es" :min="dateStart"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="height: 64px;"></div>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12">
                <v-toolbar class="elevation-0" color="grey lighten-5">
                  <v-btn @click="applyChange" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <!-- <v-btn class="elevation-0" text>Limpiar</v-btn> -->
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import { setCurrencyFormat } from '@/utils/index'
import apiEmployeeTip from '@/api/employeeTip'
import { tipHeaders, tipHeaders2 } from '@/constants/tableheaders'
import { format, parseISO } from 'date-fns'

export default {
  data: () => ({
    loadingData: true,
    loading: true,
    createdAt: null,
    total: 0,
    items: [],
    options: {},
    tipHeaders,
    tipHeaders2,
    drawerRight: false,
    search: '',
    menu1: false,
    menu2: false,
    dateStart: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    dateEnd: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    items2: [],
    options2: {}
  }),
  computed: {
    computedDateStartFormatted () {
      return this.dateStart ? format(parseISO(this.dateStart), 'dd-MM-yyyy') : ''
    },
    computedDateEndFormatted () {
      return this.dateEnd ? format(parseISO(this.dateEnd), 'dd-MM-yyyy') : ''
    }
  },
  methods: {
    openDrawer() {
      this.drawerRight = true
    },

    async getDataFromApi () {
      this.loadingData = true
      const data = await this.loadTip()
      this.total = data.total
      this.items = data.data
      const group = await this.loadTipGroup()
      this.items2 = group
      console.log(group, 'group')
      this.loadingData = false
    },
    async loadTip () {
      try {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const direction = sortDesc[0] ? 'asc' : 'desc'
        const query = {
          ...sortBy[0] ? { sort: sortBy[0] + '|' + direction } : '',
          page: page,
          per_page: itemsPerPage,
          search: this.search,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd
        }
        const { data } = await apiEmployeeTip.list(query)
        data.data = data.data.map(x => ({
          employee: `${x.employee.name} ${x.employee.lastName}`,
          email: x.employee.email,
          client: x.email_master,
          amount: x.amount,
          note: x.note,
          date: format(x.createdAt, 'dd-MM-yyyy hh:mm a')
        }))
        return data
      } catch (err) {
        console.log(err);
        const msg = err.response.data?.err === 'No Permission' ? 'Acceso no autorizado' : null
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: msg || err.response.data.err || err.response.data.message })
        return { data: [], total: 0 }
      }
    },
    async loadTipGroup () {
      try {
        const { sortBy, sortDesc } = this.options
        const direction = sortDesc[0] ? 'asc' : 'desc'
        const query = {
          ...sortBy[0] ? { sort: sortBy[0] + '|' + direction } : '',
          dateStart: this.dateStart,
          dateEnd: this.dateEnd
        }
        const { data } = await apiEmployeeTip.group(query)
        return data
      } catch (err) {
        console.log(err);
        const msg = err.response.data?.err === 'No Permission' ? 'Acceso no autorizado' : null
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: msg || err.response.data.err || err.response.data.message })
        return { data: [], total: 0 }
      }
    },
    applyChange () {
      this.getDataFromApi()
      this.drawerRight = false
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    search: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
  },
}
</script>
