export default [
  { title: 'Dashboard', icon: 'mdi-view-dashboard-outline',  link: '/' },
  { 
    title: 'General', 
    icon: 'mdi-file-chart-outline', 
    items: [
      { title: 'Reservas', link: '/general/booking' },
      { title: 'Ocupación', link: '/general/ocupacion' },
      { title: 'Ventas', link: '/general/sales' },
      { title: 'Comercial', link: '/general/comercial' },
      { title: 'Forecast', link: '/general/forecast' },
      // { title: 'Balances Activos', link: '/general/balance' },
      { title: 'Seguimiento', link: '/general/seguimiento' },
    ],
  },
  { 
    title: 'Movimientos', 
    icon: 'mdi-account-credit-card-outline', 
    items: [
      { title: 'Ordenes', link: '/accounting/order' },
      { title: 'Pagos', link: '/accounting/pay' },
      { title: 'Reservas de Espacio', link: '/placeReservation/movements' },
      { title: 'Tickets', link: '/eventTicket/movements' },
    ],
  },
  {
    title: 'POS', 
    icon: 'mdi-store-outline',
    items: [
      { title: 'Ventas en general', link: '/pos/sales' },
      { title: 'Ventas agrupadas', link: '/pos/sales-group' },
      { title: 'Detallado de ventas', link: '/pos/details' },
      { title: 'Pagos', link: '/pos/payment' },
      { title: 'Productos populares', link: '/pos/popularity' },
      { title: 'Cortesías', link: '/pos/gift' },
      { title: 'Ventas por App', link: '/app/sale' },
    ],
  },
  {
    title: 'Housekeeping', 
    icon: 'mdi-bed-outline', 
    items: [
      { title: 'Tareas', link: '/hk/task' },
    ], 
  },
  {
    title: 'Servicio', 
    icon: 'mdi-account-wrench-outline', 
    items: [
      { title: 'Ordenes', link: '/service/order' },
      { title: 'KPI POS', link: '/pos/status' },
    ], 
  },
  { 
    title: 'Empleados', 
    icon: 'mdi-account-hard-hat-outline', 
    items: [
      { title: 'Propinas', link: '/employee/tip' },
      { title: 'KPI Tareas', link: '/employee/tasks' },
    ],
  },
  { 
    title: 'Gerencia', 
    icon: 'mdi-finance', 
    items: [
      { title: 'Recaudo', link: '/gerencia/recaudo' },
      { title: 'Desempeño POS', link: '/gerencia/pos' },
      { title: 'Rendimiento POS', link: '/gerencia/performance' },
      { title: 'Balances activos', link: '/gerencia/balance' },
    ],
  },
  { 
    title: 'Review', 
    icon: 'mdi-file-chart-outline', 
    items: [
      { title: 'Comunidad', link: '/review/community' },
      { title: 'Empleados', link: '/review/employee' },
      { title: 'Productos', link: '/review/product' },
      { title: 'Servicios', link: '/review/service' },
      { title: 'Tareas', link: '/review/task' },
    ],
  },
  { title: 'Clientes', icon: 'mdi-account-multiple-outline', link: '/client/list' },
  { title: 'App', icon: 'mdi-cellphone', link: '/useTime' },
]