<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          General <v-icon>mdi-chevron-right</v-icon> Reporte comercial
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog ref="modalDates" v-model="modalDates" :return-value.sync="dates" width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field style="max-width: 250px" :loading="loading" prepend-icon="mdi-calendar" v-model="dates" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="'26/09/2024 ~ 01/12/2024'"></v-text-field>
        </template>
        <v-date-picker v-model="dates" range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modalDates = false">Cancel</v-btn>
          <v-btn text color="primary" @click="saveDates(dates)">Aceptar</v-btn>
        </v-date-picker>
      </v-dialog>
      <!-- <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn> -->
      <v-btn v-print="'#bookingTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <ComercialTable ref="comercialTable" :dates="dates"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import ComercialTable from '@/components/reports/booking/ComercialTable'
export default {
  components: { ComercialTable },
  data: () => ({
    loading: false,
    modalDates: false,
    dataExcel: [],
    dates: [moment.utc().startOf('month').format('YYYY-MM-DD'), moment.utc().endOf('month').format('YYYY-MM-DD')],
  }),
  methods: {
    saveDates(dates) {
      // console.log('evt', dates)
      this.$refs.modalDates.save(dates)
      this.$refs.comercialTable.getComercialData()
    },
    beforeDownload() {
      // this.dataExcel = this.$refs.bookingSalesTable.items
    },
    // openDrawer() {
    //   this.$refs.bookingSalesTable.openDrawer()
    // },
  },
}
</script>