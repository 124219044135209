<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-calendar</v-icon>
            <v-toolbar-title class="primary--text">Forecast</v-toolbar-title>
            <v-spacer></v-spacer> 
            <span class="text-h5 primary--text px-3"> <span class="secondary--text">Total Hospedaje:</span> {{ totalHost | toCurrency }} </span>
            <span class="text-h5 primary--text px-3"> <span class="secondary--text">Promedio:</span> {{ average | toCurrency }}</span>
          </v-toolbar>
          <!-- <v-divider class="primary"></v-divider> -->
          <v-data-table
            id="forecastTable"
            dense
            :headers="forecastsHeaders"
            :items="items"
            hide-default-footer
            :loading="loadingData"
            :server-items-length="total"
            class="elevation-0 no-wrap-table mt-2">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>
  import { setCurrencyFormat } from '@/utils/index'
  import moment from 'moment'
  import api from '@/api/report'
  import { forecastsHeaders } from '@/constants/tableheaders'
  
  export default {
    props: {
      dates: Array,
    },
    data: () => ({
      loadingData: true,
      loading: true,
      items: [],
      forecastsHeaders,
      drawerRight: false,
      modalDateStart: false,
      modalDateEnd: false,
      totalHost: 0,
      average: 0,
      total: 12,
    }),
    mounted() {
      this.getForecast()
    },
    methods: {
      async getForecast() {
        try {
          this.loadingData = true
          const { data: { forecast, total, average} } = await api.getForecast({ dateStart: moment.utc(new Date(this.dates[0])).format('YYYY-MM-DD'), dateEnd: moment.utc(new Date(this.dates[1])).format('YYYY-MM-DD') })
          this.total = forecast.length
          this.totalHost = total
          this.average = average
          this.items = forecast.map(item => this.formatDataTable(item))
          console.log('forecast', forecast)
          this.loadingData = false
        } catch (error) {
          this.loadingData = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      formatDataTable(item) {
        item.total = setCurrencyFormat(item.total)
        return item
      },
      openDrawer() {
        this.drawerRight = true
      },
    },
  }
</script>
  
  