<template>
  <v-row>
    <v-col cols="8">
      <v-card class="elevation-0">
        <v-data-table
          id="bookingTable"
          dense
          :headers="PosDetailGrossMargin"
          :items="itemsData"
          :options.sync="options"
          :server-items-length="total"
          :loading="loadingData"
          :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
          class="elevation-0 row-pointer no-wrap-table">
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status | toStatus }} 
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card :loading="loading" class="elevation-0" style="height: 100%" max-height="300px">
    <Bar id="recaudo-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { PosDetailGrossMargin } from '@/constants/tableheaders'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  

export default {
  components: {Bar},
  props: { 
    businessSelected: {type: Array, default: () => []},
    filters: Object,
    itemsData: {type: Array, default: () => []},
    total: {type: Number, default: () => 0},
    optionsTable: {type: Object, default: () => {}},
    chartData: {type: Object, default: () => {}},
    changeOption: {type: Function},
    loading: {type: Boolean, default: () => true},
    loadingData: {type: Boolean, default: () => true}, 
    },
  data: () => ({
    options: {},
    PosDetailGrossMargin,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
  }),
  methods: {
  },
  watch: {
    options: {
      handler () {
        this.changeOption(this.options)
      },
      deep: true,
    },
  },
}
</script>

