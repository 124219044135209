<template>
  <v-card class="elevation-0" :loading="loading">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular >
                <animated-number v-else class="text-h4 primary--text" :value="averageOrders" :formatValue="formatToPrice" :duration="300"/>
                <div>Valor promedio por orden</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="totalSales" :formatValue="formatToPrice" :duration="300"/>
                <div>Ventas totales</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="countOrders" :formatValue="formatToPrice" :duration="300"/>
                <div>Número de órdenes</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="realIncome" :formatValue="formatToPrice" :duration="300"/>
                <div>Ingreso real</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="totalTip" :formatValue="formatToPrice" :duration="300"/>
                <div>Propina total</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="discountOrders" :formatValue="formatToPrice" :duration="300"/>
                <div>Descuento total</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import millify from 'millify'
import AnimatedNumber from "animated-number-vue"

export default {
  components: { AnimatedNumber },
  props: {
    totalSales: { type: Number, default: 0 },
    countOrders: { type: Number, default: 0 },
    averageOrders: { type: Number, default: 0 },
    discountOrders: { type: Number, default: 0 },
    realIncome: { type: Number, default: 0 },
    totalTip: { type: Number, default: 0 },
  },
  data: () => ({
    loading: true,
  }),
  mounted() {
    this.loading = false
  },
  methods: {
    formatToPrice(value) {
      return millify(value);
    },
  },
}
</script>