import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Notifications from 'vue-notification'
import JsonExcel from "vue-json-excel"
import Print from 'vue-print-nb'
import moment from 'moment'
import millify from 'millify'

Vue.use(Print)
Vue.component("downloadExcel", JsonExcel)
Vue.use(Notifications)

Vue.use(Vuetify);

Vue.filter('toDate', function (value) {
  return moment(value).format('DD/MM/YYYY')
})

Vue.filter('toDateUTC', function (value) {
  return moment.utc(value).format('DD/MM/YYYY')
})

Vue.filter('toTimeUTC', function (value) {
  return moment.utc(value).format('hh:mm A')
})

Vue.filter('toStatus', function (value) {
  const status = {
    in_progress: 'En progreso',
    confirmed: 'Confirmado',
    not_confirmed: 'No confirmado',
    canceled: 'Cancelado',
    checked_in: 'Check In',
    checked_out: 'Check Out',
    no_show: 'Not Show',
  }
  return status[value]
})

Vue.filter('toStatusIcon', function (value) {
  const status = {
    in_progress: 'mdi-checkbox-intermediate',
    confirmed: 'mdi-check',
    not_confirmed: 'mdi-checkbox-blank-outline',
    canceled: 'mdi-cancel',
    checked_in: 'mdi-check-all',
    checked_out: 'mdi-bag-suitcase',
    no_show: 'mdi-ghost',
  }
  return status[value]
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(value);
})

Vue.filter('millify', function (value) {
  return millify(value)
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#A88E68', // Cambia este valor al color que desees
        // Otros colores que quieres mantener igual
        secondary: '#363636',
        accent: '#8c9eff',
        error: '#b71c1c',
        
        // status
        in_progress: '#7A5CA2',
        confirmed: '#5B8BBA',
        not_confirmed: '#D69054',
        canceled: '#CB4949',
        checked_in: '#53B58F',
        checked_out: '#343434',
        no_show: '#A7A7A7',
      },
    },
  },
});
