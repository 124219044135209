<template>
  <v-container>
    <v-row class="mt-5">
      <v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Balance Hospedaje
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ balanceHost | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (balanceHost / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Total
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ balanceExtra | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (balanceExtra / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Total
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ totalOrders | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (totalOrders / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Subtotal
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ subtotalOrders | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (subtotalOrders / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Pagado
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ totalPays | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (totalPays / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col><v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Faltante
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ faltante | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (faltante / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Total Hospedaje
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ totalHospedaje | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (totalHospedaje / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col><v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Noches facturadas
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ totalHospedajeClosed | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (totalHospedajeClosed / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col><v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Noches por facturar
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ totalHospedajeOpen | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (totalHospedajeOpen / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col><v-col>
        <v-card class="elevation-0" outlined>
          <v-card-title class="primary--text">
            <v-spacer></v-spacer>
            Total extra
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center">
            <span class="text-h5">{{ totalExtra | toCurrency }}</span>
            <v-card-subtitle class="text-center">
              USD {{ (totalExtra / trm) | toCurrency }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs fixed-tabs v-model="tab">
          <v-tab><v-icon class="pr-2">mdi-cash-multiple</v-icon>Cobros</v-tab>
          <v-tab><v-icon class="pr-2">mdi-credit-card-outline</v-icon>Pagos</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <OrderTable :reference="reference"/>
          </v-tab-item>
          <v-tab-item>
            <PayTable :reference="reference"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import api from '@/api/booking'
import apiPayme from '@/api/externos/payme'
import OrderTable from '@/components/booking/balance/OrderTable'
import PayTable from '@/components/booking/balance/PayTable'
export default {
  components: { OrderTable, PayTable },
  props: {
    reference: String,
  },
  data: () => ({
    search: '',
    loading: true,
    tab: null,
    totalOrders: 0,
    subtotalOrders: 0,
    totalPays: 0,
    faltante: 0,
    totalHospedaje: 0,
    totalHospedajeClosed: 0,
    totalHospedajeOpen: 0,
    totalExtra: 0,
    trm: 1,
    balanceHost: 0,
    balanceExtra: 0,
  }),
  mounted() {
    this.getTRM()
    this.getBalance()
  },
  methods: {
    async getTRM() {
      const { data: { data } } = await apiPayme.getTodayTRM()
      console.log('data', data)
      this.trm = data.value_30
    },
    async getBalance() {
      const { data: balance } = await api.getBookingBalance(this.reference)
      console.log('balance', balance)
      this.totalOrders = balance.totalOrders
      this.subtotalOrders = balance.subtotalOrders
      this.totalPays = balance.totalPays
      this.faltante = balance.faltante
      this.totalHospedajeClosed = balance.totalHospedajeClosed
      this.totalHospedajeOpen = balance.totalHospedajeOpen
      this.totalHospedaje = balance.totalHospedaje
      this.totalExtra = balance.totalExtra
      this.balanceHost = balance.balanceHost
      this.balanceExtra = balance.balanceExtra
    },
  },
  watch: {
    reference() {
      this.getBalance()
    },
  },
}
</script>