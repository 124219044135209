<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          General <v-icon>mdi-chevron-right</v-icon> Reporte de forecast
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog ref="modalDates" v-model="modalDates" :return-value.sync="dates" width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field style="max-width: 250px" prepend-icon="mdi-calendar" v-model="dates" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="'26/09/2024 ~ 01/12/2024'"></v-text-field>
        </template>
        <v-date-picker v-model="dates" range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modalDates = false">Cancel</v-btn>
          <v-btn text color="primary" @click="saveDates(dates)">Aceptar</v-btn>
        </v-date-picker>
      </v-dialog>
      <v-btn v-print="'#bookingTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <ForecastTable :dates="dates" ref="forecasTable" :setTotal="setTotal"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import ForecastTable from '@/components/reports/booking/ForecastTable'
export default {
  components: { ForecastTable },
  data: () => ({
    dataExcel: [],
    total: 0,
    modalDates: false,
    dates: [moment.utc().startOf('month').format('YYYY-MM-DD'), moment.utc().endOf('month').format('YYYY-MM-DD')],
  }),
  methods: {
    beforeDownload() {
      this.dataExcel = this.$refs.forecasTable.items
    },
    openDrawer() {
      this.$refs.forecasTable.openDrawer()
    },
    setTotal(total) {
      this.total = total
    },
    saveDates(dates) {
      // console.log('evt', dates)
      this.$refs.modalDates.save(dates)
      this.$refs.forecasTable.getForecast()
    },
  },
}
</script>