<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :loading="loading" class="elevation-0">
          <v-card-text>
            <v-row>
              <v-col>
                <v-subheader v-if="!loading">
                  Reserva creada el {{ booking?.createdAt | toDateUTC }}
                  <br/>
                  Ultima actualizacion {{ booking?.updatedAt | toDateUTC }}
                </v-subheader>
                <v-skeleton-loader v-else style="height: 30px; width: 200px;" type="image"></v-skeleton-loader>
              </v-col>
              <v-col class="text-right">
                <span v-if="!loading" class="text-h5" :class="{[`${booking?.status}--text`]: true}">
                  <v-icon :color="`${booking?.status}`">{{ booking?.status | toStatusIcon }}</v-icon>
                  {{ booking?.status | toStatus }}
                </span>
                <v-skeleton-loader v-else style="height: 30px; width: 100px; float: right;" type="image"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-toolbar flat color="transparent">
                  <v-toolbar-title class="secondary--text">Resumen de reserva</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon color="secondary">mdi-book</v-icon>
                </v-toolbar>
                <v-divider class="primary"></v-divider>
                <!-- <hr class="primary"> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card :loading="loading" class="elevation-0">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card flat>
                          <v-card-actions class="d-flex">
                            <div style="height: 100%; width: 100%;">
                              <div v-if="loading" >
                                <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                                <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                                <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                              </div>
                              <table v-else style="width:100%;" class="booking-info-table">
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-calendar</v-icon><strong class="grey--text text--darken-1">Check In</strong> {{ booking?.dateStart | toDateUTC }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-calendar</v-icon><strong class="grey--text text--darken-1">Check Out</strong> {{ booking?.dateEnd | toDateUTC }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-phone</v-icon><strong class="grey--text text--darken-1">Contacto</strong> {{ booking?.wayContactName }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-bed</v-icon><strong class="grey--text text--darken-1">Habitacion</strong> {{ booking?.place?.typology?.name }} - {{ booking?.place?.name }}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-paw</v-icon><strong class="grey--text text--darken-1">Con Mascota</strong> {{ booking?.withPet }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-flag</v-icon><strong class="grey--text text--darken-1">Con IVA</strong> {{ booking?.isNational }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-security</v-icon><strong class="grey--text text--darken-1">Con garantia</strong> {{ booking?.withWarranty }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-cash</v-icon><strong class="grey--text text--darken-1">Channel Paid</strong> {{ booking?.channelProvidedCreditCard }}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-bag-personal</v-icon><strong class="grey--text text--darken-1">Estadia</strong> {{ booking?.isShortStay ? 'Corta' : 'Larga' }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-moon-waning-crescent</v-icon><strong class="grey--text text--darken-1">Noches</strong> {{ booking?.rates.length }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-account-multiple</v-icon><strong class="grey--text text--darken-1">Adultos</strong> {{ booking?.adults }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-teddy-bear</v-icon><strong class="grey--text text--darken-1">Niños</strong> {{ booking?.children }}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-cloud-outline</v-icon><strong class="grey--text text--darken-1">OTA</strong> {{ booking?.cloudID }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-cloud-minus-outline</v-icon><strong class="grey--text text--darken-1">Sub OTA</strong> {{ booking?.subCloudID }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-cash-100</v-icon><strong class="grey--text text--darken-1">Precio por mes</strong> {{ booking?.priceByMonth | toCurrency }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-sigma</v-icon><strong class="grey--text text--darken-1">Precio total</strong> {{ booking?.total | toCurrency }}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div>
                              <v-avatar style="float: right" class="profile" color="grey lighten-3" size="150" rounded>
                                <v-img :src="`http://44.211.71.232/images/lg/${booking?.place?.typology?.photos[0]}`"></v-img>
                              </v-avatar>
                            </div>
                          </v-card-actions>
                          <v-expansion-panels flat focusable popout class="mt-5">
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <template v-slot:actions>
                                  <v-icon color="primary">
                                    $expand
                                  </v-icon>
                                </template>
                                <div class="text-center primary--text">
                                  Ver mas...
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col class="mt-0 pt-0">
                <v-toolbar flat color="transparent">
                  <v-toolbar-title class="secondary--text">Dueño de reserva</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon color="secondary">mdi-account</v-icon>
                </v-toolbar>
                <v-divider class="primary"></v-divider>
                <!-- <hr class="primary"> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card :loading="loading" class="elevation-0">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card flat>
                          <v-card-actions class="d-flex">
                            <div>
                              <v-avatar style="float: right" class="profile" color="grey lighten-3" size="150">
                                <v-img :src="`https://cdn.kiinliving.com/images/md/${booking?.user?.photo}`"></v-img>
                              </v-avatar>
                            </div>
                            <div style="height: 100%; width: 100%;">
                              <div v-if="loading" >
                                <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                                <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                                <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
                              </div>
                              <table v-else style="width:100%;" class="booking-info-table ml-10">
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">Nombre</strong> {{ booking?.user?.name }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">Apellido</strong> {{ booking?.user?.lastName }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">Tipo de ID</strong> {{ booking?.user?.documentType }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">Documento</strong> {{ booking?.user?.documentID }}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-mail-outline</v-icon><strong class="grey--text text--darken-1">Email</strong> {{ booking?.user?.email }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-flag</v-icon><strong class="grey--text text--darken-1">Nacionalidad</strong> {{ booking?.user?.nationality }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">Genero</strong> {{ booking?.user?.gender }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">Tipo de cliente</strong> {{ booking?.user?.category }}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-map-marker-account</v-icon><strong class="grey--text text--darken-1">Pais</strong> {{ booking?.user?.country }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-map-marker-account</v-icon><strong class="grey--text text--darken-1">Estado</strong> {{ booking?.user?.state }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-map-marker-account</v-icon><strong class="grey--text text--darken-1">Ciudad</strong> {{ booking?.user?.city }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-map-marker-account</v-icon><strong class="grey--text text--darken-1">Zipcode</strong> {{ booking?.user?.zipcode }}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-phone</v-icon><strong class="grey--text text--darken-1">Telefono</strong> {{ booking?.user?.phone }}
                                    </span>
                                  </td>
                                  <td class="px-2">
                                    <span>
                                      <v-icon small class="px-1 primary--text">mdi-card-account-details-outline</v-icon><strong class="grey--text text--darken-1">App ID</strong> {{ booking?.user?.masterID }}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </v-card-actions>
                          <v-expansion-panels flat focusable popout class="mt-5">
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <template v-slot:actions>
                                  <v-icon color="primary">
                                    $expand
                                  </v-icon>
                                </template>
                                <div class="text-center primary--text">
                                  Ver mas...
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="mt-10">
                      <v-col>
                        <v-tabs fixed-tabs v-model="tab">
                          <v-tab><v-icon class="pr-2">mdi-cash-multiple</v-icon>Balance</v-tab>
                          <v-tab><v-icon class="pr-2">mdi-moon-waning-crescent</v-icon>Noches</v-tab>
                          <v-tab><v-icon class="pr-2">mdi-account-group</v-icon>Huespedes</v-tab>
                          <v-tab><v-icon class="pr-2">mdi-file</v-icon>Adjuntos</v-tab>
                          <v-tab><v-icon class="pr-2">mdi-list-box</v-icon>Notas</v-tab>
                          <!-- <v-tab><v-icon class="pr-2">mdi-tree</v-icon>Logs</v-tab> -->
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                          <!-- Rates -->
                          <v-tab-item>
                            <Balance :reference="reference"/>
                          </v-tab-item>
                          <!-- Rates -->
                          <v-tab-item>
                            <v-card :loadin="loading" class="elevation-2">
                              <v-card-text>
                                <Rates :rates="booking?.rates"/>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <!-- Huespedes -->
                          <v-tab-item>
                            <Guests :reference="reference"/>
                          </v-tab-item>
                          <v-tab-item>Adjuntos component</v-tab-item>
                          <v-tab-item>Notas component</v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Rates from '@/components/booking/Rates'
import Guests from '@/components/booking/Guests'
import Balance from '@/components/booking/Balance'
import api from '@/api/booking'
export default {
  components: { Guests, Rates, Balance },
  props: {
    reference: String,
  },
  data: () => ({
    loading: true,
    tab: null,
    booking: {},
  }),
  mounted() {
    this.getBookingInfo()
  },
  methods: {
    async getBookingInfo() {
      try {
        this.loading = true
        this.booking = null
        const { data: booking } = await api.getBookingByRef(this.reference)
        // console.log('booking', booking)
        this.booking = booking
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({
          type: 'error',
          group: 'noti',
          title: 'Error con comunicacion con el servidor.',
          text: 'No se pudo establecer comunicacion con servidor.',
        })
      }
    },
  },
  watch: {
    reference(newVal) {
      console.log('reference changed', newVal)
      this.getBookingInfo()
    }
  },
}
</script>

<style scoped>
.booking-info-table td {
  padding-bottom: 20px;
}

.booking-info-table tr:last-child td {
  padding-bottom: 0px;
}

</style>