import axios from 'axios'

const { VUE_APP_API_URL } = process.env

axios.defaults.baseURL = VUE_APP_API_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  config.headers['Authorization'] = `Bearer ${token}`
  return config
})

axios.interceptors.response.use(res => res, err => {
  /**
   * @todo: Manjeador de errores comunes manejables
   */
  console.log('error interceptado', err.response)
  if (err.response?.status === 403) {
    // vuex.dispatch('signOut')
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('employee')
    console.log('logout msg', err)
    location.reload()
    return 
  }

  // if (err.reponse.data.msg === 'missing param (country or iso2)' ||  'missing param (country)') { return }
  console.log('error', err)
  throw err.response?.data?.message ? new Error(`${err.response.data.message}`) : err 
})

export default axios