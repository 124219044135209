import axios from './index'

export default {
  getDashboard() {
    return axios.get('/api/v1/report/dashboard')
  },
  getOcupacion(params) {
    return axios.get('/api/v1/report/booking/ocupacion', { params })
  },
  getBookingSales(params) {
    return axios.get('/api/v1/report/booking/sales', { params })
  },
  getBookingComercial(params) {
    return axios.get('/api/v1/report/booking/comercial', { params })
  },
  getForecast(params) {
    return axios.get('/api/v1/report/booking/forecast', { params })
  },
}