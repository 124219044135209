
<template>
  <v-card :loading="loading" class="elevation-0" style="height: 100%">
    <Bar id="recaudo-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
</template>
  
<script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    components: { Bar },
    props: {
      totalMargin: { type: Array, default:[] },
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: [
          { 
            label: 'Margen real promedio por unidad de negocio',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      }
    }),
    mounted() {
    },
    methods: {
      getDataBar() {
      const filterData = this.totalMargin.filter(e=>e.total>0)
      const total = filterData.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
      this.chartData = {
        labels: filterData.map(e=>`${e.business.name} ${total > 0 ? ((e.total * 100) / total).toFixed(2) : 0}%`),
        datasets: [{
          label: 'Margen real promedio por unidad de negocio',
          backgroundColor: '#a88e68',
          data: filterData.map(e=>e.total)
        }]
      }
      this.loading = false
    },
    },
     watch: {
      totalMargin: {
        handler () {
          this.getDataBar()
        },
        deep: true,
      },
  },
  }
</script>

<style>
#test-chart {
  margin: auto;
}
</style>