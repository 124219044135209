<template>
    <v-container fluid>
      <v-toolbar dense color="transparent" flat>
        <v-toolbar-title class="grey--text text--darken-2">
          <v-subheader>
            General <v-icon>mdi-chevron-right</v-icon> Reporte de ventas
          </v-subheader>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-toolbar-title class="text-h4 primary--text">{{ total | toCurrency }}</v-toolbar-title> -->
        <v-btn icon @click="openDrawer()">
          <v-icon>mdi-filter-cog</v-icon>
        </v-btn>
        <v-btn v-print="'#bookingTable'" icon>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
          <v-btn icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel>
      </v-toolbar>
      <v-row>
        <v-col>
          <BookingSalesTable ref="bookingSalesTable" :setTotal="setTotal"/>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import BookingSalesTable from '@/components/reports/booking/BookingSalesTable.vue'
  export default {
    components: { BookingSalesTable },
    data: () => ({
      dataExcel: [],
      total: 0,
    }),
    methods: {
      async beforeDownload() {
        this.dataExcel = await this.$refs.bookingSalesTable.getForExcell()
      },
      openDrawer() {
        this.$refs.bookingSalesTable.openDrawer()
      },
      setTotal(total) {
        this.total = total
      },
    },
  }
  </script>