<template>
  <v-card class="elevation-0" :loading="loading">
    <div class="text-center"><span>Promedio en minutos</span></div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular >
                <animated-number v-else class="text-h4 primary--text" :value="timeAverage.time1" :duration="300"/>
                <div>Abierto - En preparación</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="timeAverage.time2" :duration="300"/>
                <div>En preparación - Listo para entregar</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="timeAverage.time3" :duration="300"/>
                <div>Listo para entregar - Cerrado</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="elevation-0" width="100%" color="blue-grey lighten-5">
              <v-card-text class="text-center">
                <v-progress-circular v-if="loading" color="primary" indeterminate class="my-1"></v-progress-circular>
                <animated-number v-else class="text-h4 primary--text" :value="timeAverage.time4" :duration="300"/>
                <div>Abierto - Cerrado</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import millify from 'millify'
import AnimatedNumber from "animated-number-vue"

export default {
  components: { AnimatedNumber },
  props: {
    timeAverage: { type: Object, default: {}},
  },
  data: () => ({
    loading: true,
  }),
  mounted() {
    this.loading = false
  },
  methods: {
    // formatToPrice(value) {
    //   return millify(value);
    // },
  },
}
</script>