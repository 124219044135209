<template>
  <div>
    <v-sheet tile height="64" class="d-flex">
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title class="text-uppercase primary--text" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-select :items="areas" label="Area" style="max-width: 270px;" item-text="name" item-value="_id" clear hide-details class="mr-4" v-model="selectedArea"></v-select>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ types.find(t => t.value == type).text }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list v-for="t in types" :key="t.value">
            <v-list-item @click="type = t.value">
              <v-list-item-title>{{ t.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :type="type"
        :events="events"
        event-overlap-mode="column"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        @click:more="viewDay"
        @click:date="viewDay"
        @click:event="showEvent"
      ></v-calendar>
    </v-sheet>
    <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar :color="selectedEvent.color" dark>
          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-list class="transparent">
            <v-list-item class="px-0">
              <v-list-item-title>{{ selectedEvent.taskOption }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-0">
              <v-list-item-title>{{ selectedEvent.employee }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="secondary" @click="selectedOpen = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app >
      <v-toolbar dark class="elevation-0 secondary">
        <v-toolbar-title> <strong>Leyenda</strong></v-toolbar-title>
      </v-toolbar>
      <v-container>
        <div v-for="(l, i) in legend" :key="i" :class="`event-legend white--text p-1 ${l.color}`">{{ l.name }}</div>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiTask from '@/api/task'
import apiArea from '@/api/employeeArea'
export default {
  data: () => ({
    dateStart: '',
    dateEnd: '',
    drawerRight: false,
    type: 'month',
    types: [{ value: 'month', text: 'Mes' }, { value: 'week', text: 'Semana' }, { value: 'day', text: 'Día' }],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    events: [],
    legend: [],
    colors:['red', 'red lighten-1', 'red darken-4', 'pink', 'pink darken-4', 'purple', 'purple lighten-2', 'deep-purple', 'indigo', 'indigo accent-4', 'blue', 'blue lighten-2', 'cyan', 'cyan darken-4', 'teal', 'teal accent-3', 'green', 'green darken-4', 'green accent-4', 'light-green', 'lime', 'deep-orange accent-4', 'yellow accent-4', 'amber', 'orange', 'orange darken-4', 'brown darken-4', 'brown', 'brown lighten-1', 'grey', 'grey darken-4'],
    areas: [],
    selectedArea: '',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  created () {
    this.loadArea()
  },
  methods: {
    async getEvents ({ start, end }) {
      const events = []
      this.dateStart = start.date
      this.dateEnd = end.date
      const tasks = await this.loadTask(start.date, end.date)
      
      this.legend = []
      const colorsCopy = [...this.colors]

      tasks.data.forEach(task => {
        const colorCount = colorsCopy.length
        const pos = this.rnd(0, colorCount - 1)
        let color = 'grey'
        const legendFind = this.legend.find(l => l.name == task.taskOption.name)
        if (legendFind) {
          color = legendFind.color
        } else {
          color = colorCount > 0 ? colorsCopy.splice(pos, 1)[0] : 'grey'
          this.legend.push({
            name: task.taskOption.name,
            color: color
          })
        }
        events.push({
          id: task._id,
          name: task.place.name,
          start: new Date(task.dateStart),
          end: new Date(task.dateEnd),
          employee: task.employee.name + ' ' + task.employee.lastName,
          taskOption: task.taskOption.name,
          color: color,
          timed: true
        })
      })
      console.log(this.legend)
      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    async loadTask (dateStart, dateEnd) {
      const { data: task} = await apiTask.fetch({ dateStart, dateEnd, area: this.selectedArea })
      console.log('==============================================')
      console.log(task)
      return task
    },
    async loadArea () {
      const { data: area } = await apiArea.list()
      console.log(area, 'area')
      this.areas = area.data
      this.areas.unshift({ _id: '', name: 'Todas' })
    },
    openDrawer () {
      this.drawerRight = true
    },
    viewDay ({ date }) {
      this.value = date
      this.type = 'day'
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }
  },
  watch: {
    selectedArea () {
      this.getEvents({ start: { date: this.dateStart}, end: { date: this.dateEnd } })
    }
  }
}
</script>

<style scoped>
.event-legend {
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 15px;
  margin-bottom: 5px;
}
</style>