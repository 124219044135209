<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-toolbar v-if="!reference" dense flat color="transparent">
            <v-icon left color="primary">mdi-cash</v-icon>
            <v-toolbar-title class="primary--text">Movimientos</v-toolbar-title>
            <v-divider v-if="!reference" class="primary mx-7"></v-divider>
          </v-toolbar>
          <v-data-table
            :loading="loadingData"
            id="orderTable"
            dense
            :headers="movementsQRHeaders"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
            class="elevation-0 no-wrap-table mt-5 row-pointer">
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="orderDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>{{ orderName }}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn icon dark @click="orderDetail = false">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <OrderDetail :_id="orderID"/>
      </v-card>
    </v-dialog>
    <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
      <v-toolbar dark class="elevation-0 secondary">
        <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-filter-cog</v-icon>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col class="pb-0">
            <v-subheader class="secondary--text" style="height: auto;">Eventos</v-subheader>
            <v-select :loading="loading" prepend-icon="mdi-ghost-outline" v-model="event" :items="eventArray" hide-details dense placeholder="Todas"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-subheader class="secondary--text" style="height: auto;">
              Fecha de creacion
              <v-spacer></v-spacer>
              <span>
                <v-checkbox :loading="loading" small v-model="createdAtRange" :label="`Rango`" hide-details dense></v-checkbox>
              </span>
            </v-subheader>
            <v-dialog ref="modalCreatedAt" v-model="modalCreatedAt" :return-value.sync="createdAt" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :loading="loading" prepend-icon="mdi-calendar-badge" v-model="createdAt" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`${createdAtRange ? '26/09/2024 ~ 01/12/2024' : '26/09/2024' }`"></v-text-field>
              </template>
              <v-date-picker v-if="!createdAtRange" v-model="createdAt" @input="$refs.modalCreatedAt.save(createdAt)"></v-date-picker>
              <v-date-picker v-else v-model="createdAt" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalCreatedAt = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.modalCreatedAt.save(createdAt)">Aceptar</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div style="height: 64px;"></div>
          </v-col>
        </v-row>
        <v-footer fixed padless>
          <v-row>
            <v-col class="text-center" cols="12" >
              <v-toolbar  class="elevation-0" color="grey lighten-5">
                <v-btn @click="getOrders" class="elevation-0 primary--text" text>Aplicar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="elevation-0" text>Limpiar</v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-footer>
      </v-container>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import OrderDetail from '@/components/booking/balance/OrderDetail.vue'
import { setCurrencyFormat } from '@/utils/index'
import moment from 'moment'
import api from '@/api/event'
import { movementsQRHeaders } from '@/constants/tableheaders'
export default {
  components: { OrderDetail },
  data: () => ({
    loading: false,
    drawerRight: false,
    loadingData: true,
    movementsQRHeaders,
    items: [],
    options: {},
    total: 0,
    orderName: '',
    createdAt: null,
    modalCreatedAt: false,
    createdAtRange: false,
    orderDetail: false,
    event: null,
    orderID: null,
    types: [
      { text: 'Todas', value: null },
      { text: 'Reales', value: false },
      { text: 'Virtuales', value: true },
    ],
    business: null,
    eventArray: [],
  }),
  props: {
    reference: String,
  },
  mounted() {
    this.getBusiness()
    this.getOrders()
  },
  methods: {
    async getOrders() {
      try {
        this.loadingData = true
        console.log('this.orderOptions', this.options)
        const query = {
          ...this.options,
          event: this.event,
          createdAt: this.createdAtRange 
            ? this.createdAt ? [moment(this.createdAt[0]).format('YYYY-MM-DD'), moment(this.createdAt[1]).format('YYYY-MM-DD')] : null
            : this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : null,
        }
        console.log("query ", query)
        const { data: { data: orders, total } } = await api.listMovements(query)
        this.items = orders.map(item => this.formatDataTable(item))
        this.total = total
        console.log('data ', orders)
        console.log('total ', total)
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formatDate(dateStart, dateEnd) {
      const fechaReserva= new Date(moment.utc(dateStart).format('YYYY-MM-DD HH:mm'))
      const año = (fechaReserva).getFullYear()
      const mes = String(fechaReserva.getMonth() + 1).padStart(2, '0');
      const dia = String(fechaReserva.getDate()).padStart(2, '0')
      const horasStart = String(fechaReserva.getHours()).padStart(2, '0')
      const minutosStart = String(fechaReserva.getMinutes()).padStart(2, '0')
      const fecha = new Date(moment.utc(dateEnd).format('YYYY-MM-DD HH:mm'))
      const horas = String(fecha.getHours()).padStart(2, '0')
      const minutos = String(fecha.getMinutes()).padStart(2, '0')
      return `${año}-${mes}-${dia} ${horasStart}:${minutosStart} / ${horas}:${minutos}`
    },
    formatDataTable(item) {
      item.createdAt = moment.utc(item.createdAt).format('DD/MM/YYYY')
      item.subtotal = setCurrencyFormat(item.subtotal)
      item.total = setCurrencyFormat(item.total)
      item.by_client_app = item.by_client_app === true? 'APP':'PMS'
      item.user = item.event_QR? item.event_QR.user.name+" "+item.event_QR.user.lastName:''
      item.eventTitle = item.event_QR.eventTitle
      item.count = item.event_QR.count
      return item
    },
    openDrawer() {
      this.drawerRight = true
    },
    async getBusiness() {
      try {
        this.loading = true
        const { data: { data: events } } = await api.list({})
        this.eventArray =  events.map(v => ({ text: `${v.title}: ${ moment.utc(v.dateStart).format('DD/MM/YYYY')}-${ moment.utc(v.dateEnd).format('DD/MM/YYYY')}`, value: v._id }))
        this.loading = false
      } catch (error) {
        this.loadingData = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    onSearchBusiness(search) {
      console.log('search', search)
    },
    getAllItems() {
      try {
        this.loadingData = true
        let itemsData = this.items
        this.loadingData = false
        return itemsData        
      } catch (error) {
        this.loadingData = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
  },
  watch: {
    reference() {
      this.getOrders()
    },
    options: {
      deep: true,
      handler() {
        this.getOrders()
      },
    },
    createdAtRange() {
      this.createdAt = null
    },
  },
}
</script>