export const setCurrencyFormat = function (value) {
  value = Number(value)
  if (value === NaN) { return 'Error setCurrencyFormat' }
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  });
  return formatter.format(value);
}
