<template>
    <v-container fluid>
      <v-toolbar dense color="transparent" flat>
        <v-toolbar-title class="grey--text text--darken-2">
          <v-subheader>
            General <v-icon>mdi-chevron-right</v-icon> Reporte de seguimiento
          </v-subheader>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="openDrawer()">
          <v-icon>mdi-filter-cog</v-icon>
        </v-btn>
        <v-btn v-print="'#bookingTable'" icon>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
          <v-btn icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel>
      </v-toolbar>
      <v-row>
        <v-col>
          <SeguimientosTable ref="bookingTable"/>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import SeguimientosTable from '@/components/reports/booking/SeguimientosTable'
  export default {
    components: { SeguimientosTable },
    data: () => ({
      dataExcel: [],
    }),
    methods: {
      beforeDownload() {
        this.dataExcel = this.$refs.bookingTable.items
      },
      openDrawer() {
        this.$refs.bookingTable.openDrawer()
      },
    },
  }
  </script>