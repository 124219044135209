import axios from './index'

export default {
  getBookingByRef(ref) {
    return axios.get(`/api/v1/booking/ref/${ref}`)
  },
  getBookingBalance(ref) {
    return axios.get(`/api/v1/booking/balance/${ref}`)
  },
  getBookingGuestsAndVisitorsByRef(ref) {
    return axios.get(`/api/v1/booking/guests/${ref}`)
  },
  table(params) {
    return axios.get('/api/v1/report/booking', { params })
  },
  getRecaudoStatistics(params) {
    return axios.get('/api/v1/report/booking/recaudoStatistics', { params })
  },
  getRecaudoPotecialVsReal(params) {
    return axios.get('/api/v1/report/booking/recaudoPotecialVsReal', { params })
  },
  getRecaudoNationalities(params) {
    return axios.get('/api/v1/report/booking/recaudoNationalities', { params })
  },
  getActiveBalancesByRooms(params) {
    return axios.get('/api/v1/report/booking/activeBalances', { params })
  },
}