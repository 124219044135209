<template>
  <Bar ref="bar" v-if="loaded" :data="chartData" :options="chartOptions" :style="myStyles" />
</template>

<script>
import apiMaster from '@/api/externos/master'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { format } from 'date-fns'
import { Bar } from 'vue-chartjs'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export default {
  components: { Bar },
  props: ['date', 'tabWidth'],
  data: () => ({
    loaded: false,
		chartData: {
      labels: [],
      datasets: []
		},
		chartOptions: {
			responsive: true
		}
	}),
  computed: {
    myStyles () {
      return {
        ... this.tabWidth ? { width: `${this.tabWidth}px !important` }: {},
        position: 'relative'
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loaded = false
      console.log(this.date)
      const query = {
        dateStart: `${this.date[0]} 00:00`,
        dateEnd: this.date[1] ? `${this.date[1]} 00:00` : `${this.date[0]} 00:00`
      }
      // if (new Date(this.date[0]) - new Date(this.date[1]))
      console.log(query)
      const { data } = await apiMaster.useTimeGroupReport(query)
      console.log(data, 'data')
      // x._id.split('-')[2]
      this.chartData = {
        labels: data.map(x => format(new Date(`${x._id} 00:00`), 'dd-MMM')),
        datasets: [{
          label: 'Promedio de horas',
          backgroundColor: '#A88E68',
          data: data.map(x => (x.total / x.count / 3600))
        }]
      }
      this.loaded = true
    }
  },
  watch: {
    // date () {
    //   this.loadData()
    // }
  }
}
</script>
