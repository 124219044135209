
<template>
<div class="text-center">
  <h5>Número de productos vendidos por unidad de negocio</h5>
  <v-card :loading="loading" class="elevation-0" style="height: 100%">
    <Pie id="recaudo-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
</div>
</template>
  
<script>
  import apiOrder from "@/api/externos/orderPos";
  import { Pie } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale, ArcElement } from 'chart.js'
  ChartJS.register(Title, Tooltip, Legend, CategoryScale, PointElement, LinearScale, LineElement, ArcElement)
  
  export default {
    components: { Pie },
    props: {
      filters: Object,
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: [
          { 
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    mounted() {
      this.getDataMetrics()
    },
    methods: {
      async getDataMetrics() {
        try {
          let dates = {}
          this.loading = true
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate,
            gift: this.filters.gift
          }
          const {data: {data:dataOrder} } = await apiOrder.getSoldProducts(query)
          // console.log(dataOrder,'dataOrder');
          const total = dataOrder.reduce((accumulator, currentValue) => accumulator + currentValue.totalQty, 0);
          this.chartData = {
          labels:  dataOrder.map(e=>`${e.business} ${total > 0 ? ((e.totalQty * 100) / total).toFixed(2) : 0}%`),
          datasets: [{
            backgroundColor: ['#a88e68', '#707070de', '#695339', '#bcaaa4', '#5E5E5E', '#8A7D50' ],
            data: dataOrder.map(e=>e.totalQty)
          }]
        }
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log(error, "error")
        }
     },
    },
  }
  </script>