<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-book</v-icon>
            <v-toolbar-title class="primary--text">Ingresos</v-toolbar-title>
            <v-divider class="primary mx-7"></v-divider>
          </v-toolbar>
          <v-data-table
            dense
            :loading="loading"
            id="serviceTable"
            :headers="serviceOrderHeaders"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
            class="elevation-0 mt-2 no-wrap-table"
          >
          <template v-slot:item.service.invoice="{ item }">
            {{ item.service.invoice ? `#${item.service.invoice}` : '-' }}
          </template>
          <template v-slot:item.service="{ item }">
            {{ item.service.service.name }}
          </template>
          <template v-slot:item.user="{ item }">
            {{ item.user.name }} {{ item.user.lastName }}
          </template>
          <template v-slot:item.subtotal="{ item }">
            {{ item.subtotal | toCurrency }}
          </template>
          <template v-slot:item.taxAmount="{ item }">
            {{ item.taxAmount | toCurrency }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.total | toCurrency }}
          </template>
          <template v-slot:item.extra="{ item }">
            {{ item.extra ? item.extra.amount : 0 | toCurrency }}
          </template>
          <template v-slot:item.credit="{ item }">
            {{ item.credit | toCurrency }}
          </template>
          <template v-slot:item.discount="{ item }">
            {{ item.discount?.amount | toCurrency }}
          </template>
          <template v-slot:item.totalPay="{ item }">
            {{ calTotalPay(item) | toCurrency }}
          </template>
          <template v-slot:item.employee="{ item }">
            {{ item.employee ? `${item.employee.name} ${item.employee.lastName}`: '' }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ dateFormat(item.createdAt) }}
          </template>
        </v-data-table>
        </v-card-text>
      </v-card>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateStartFormatted"
                    clearable
                    label="Inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dateStart = null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateStart" @change="menu1 = false" locale="es" :max="dateEnd"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateEndFormatted"
                    clearable
                    label="Fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dateEnd = null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateEnd" @change="menu2 = false" locale="es" :min="dateStart"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn class="elevation-0 primary--text" text @click="getDataFromApi">Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment'
  import api from '@/api/serviceOrder'
  import { serviceOrderHeaders } from '@/constants/tableheaders'

  export default {
    props: {},
    data: () => ({
      serviceOrderHeaders,
      items: [],
      options: {},
      total: 0,
      loading: false,
      drawerRight: false,
      dateStart: moment().startOf('month').format('yyyy-MM-DD'),
      dateEnd: moment().endOf('month').format('yyyy-MM-DD'),
      menu1: false,
      menu2: false,
    }),
    computed: {
      computedDateStartFormatted () {
        return this.dateStart ? moment(this.dateStart).format('DD-MM-yyyy') : ''
      },
      computedDateEndFormatted () {
        return this.dateEnd ? moment(this.dateEnd).format('DD-MM-yyyy') : ''
      },
    },
    mounted() {
    },
    methods: {
      openDrawer () {
        this.drawerRight = true
      },
      async getDataFromApi () {
        this.loading = true
        const data = await this.getData()
        this.total = data.total
        this.items = data.data
        this.loading = false
      },
      async getData () {
        try {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          const direction = sortDesc[0] ? 'asc' : 'desc'
          const query = {
            ...sortBy[0] ? { sort: sortBy[0] + '|' + direction } : '',
            page: page,
            per_page: itemsPerPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd
          }
          console.log(query)
          const { data } = await api.fetchPaginate(query)
          console.log('service', data)
          return data
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({
            type: 'error',
            group: 'noti',
            title: 'Error con comunicacion con el servidor.',
            text: 'No se pudo establecer comunicacion con servidor.',
          })
        }
      },
      calcTotal (val) {
        let total = val.total
        if (val?.extra?.amount) total -= val.extra.amount
        return total
      },
      calTotalPay (val) {
        let total = val.total
        if (val?.extra?.amount) total += val.extra.amount
        if (val.credit) total -= val.credit
        if (val?.discount?.amount) total -= val.discount.amount
        return total
      },
      dateFormat (date) {
        return moment(date).format('DD/MM/yyyy hh:mm a')
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true
      },
    },
  }
</script>
