<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-bed-empty</v-icon>
            <v-toolbar-title class="primary--text">Ventas por tipologia</v-toolbar-title>
            <v-spacer></v-spacer> 
            <span class="text-h5 primary--text">Total: {{ totalCalculate | toCurrency }}</span>
          </v-toolbar>
          <!-- <v-divider class="primary"></v-divider> -->
          <v-tabs vertical background-color="grey lighten-5" class="mt-5" icons-and-text>
            <template v-for="typology in comercial">
              <v-tab>
                {{ typology.name }} 
              </v-tab>
            </template>
            <v-tab-item v-for="(typology, i) in comercial" :key="i">
              <v-container>
                <v-data-table
                  :loading="loading"
                  id="ocupacionTable"
                  :headers="comercialHeaders"
                  :items="typology.rooms"
                  :items-per-page="typology.rooms.length"
                  :single-expand="true"
                  show-expand
                  class="elevation-0"
                  hide-default-footer>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td class="no-show-hover" :colspan="headers.length">
                      <v-data-table
                        id="bookingTable"
                        :headers="bookingHeaders"
                        :items="item.bookings"
                        :options.sync="options"
                        :server-items-length="item.bookings.length"
                        @click:row="openBookingDetail"
                        :loading="loading"
                        hide-default-footer
                        class="elevation-0 row-pointer no-wrap-table my-5">
                        <template v-slot:[`item.status`]="{ item }">
                          {{ item.status | toStatus }} 
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                </v-data-table>
                <v-toolbar dense flat class="mt-8">
                  <v-spacer></v-spacer>
                  <span class="text-h5 grey--text text-uppercase">{{ typology.name }}: <span class="primary--text">{{ typology.totalByTypology | toCurrency }}</span></span>
                </v-toolbar>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="bookinDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>{{ reference }}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn icon dark @click="bookinDetail = false">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <BookingDetail :reference="reference"/>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment'
import BookingDetail from '@/components/booking/BookingDetail.vue'
import api from '@/api/report'
import { setCurrencyFormat } from '@/utils/index'
import { comercialHeaders, bookingHeaders } from '@/constants/tableheaders'
export default {
  components: { BookingDetail },
  props: {
    dates: Array,
  },
  data: () => ({
    loading: true,
    comercialHeaders,
    bookingHeaders,
    options: {},
    comercial: [],
    count: 0,
    bookinDetail: false,
    reference: '',
    totalCalculate: 0,
  }),
  mounted() {
    this.getComercialData()
  },
  methods: {
    async getComercialData() {
      try {
        this.loading = true
        const { data: comercial } = await api.getBookingComercial({ dateStart: this.dates[0], dateEnd: this.dates[1] })
        console.log('comercial', comercial)
        this.comercial= comercial.map(data => this.formatDataTable(data))
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({
          type: 'error',
          group: 'noti',
          title: 'Error con comunicacion con el servidor.',
          text: 'No se pudo establecer comunicacion con servidor.',
        })
      }
    },
    formatDataTable(data) {
      data.totalByTypology = data.rooms.reduce((a, b) => a + b.totalSales, 0)
      data.rooms = data.rooms.map(item => {
        this.totalCalculate = Number(this.totalCalculate) + Number(item.totalSales)
        item.totalSales = setCurrencyFormat(item.totalSales)
        item.expanded = []
        item.bookings = item.bookings.map(booking => {
          booking.dateStart = moment.utc(booking.dateStart).format('DD/MM/YYYY')
          booking.dateEnd = moment.utc(booking.dateEnd).format('DD/MM/YYYY')
          booking.createdAt = moment.utc(booking.createdAt).format('DD/MM/YYYY')
          booking.isVirtual = booking.isVirtual ? 'SI' : 'NO'
          booking.total = setCurrencyFormat(booking.total)
          return booking
        })
        return item
      })
      return data
    },
    openBookingDetail(row) {
      console.log('clicked row', row)
      this.reference = row.ref
      this.bookinDetail = true
    },
  },
}
</script>