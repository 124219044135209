<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-cash-plus</v-icon>
            <v-toolbar-title class="primary--text">KPI Tareas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select :items="areas" label="Area" style="max-width: 270px;" item-text="name" item-value="_id" clear class="mr-4" v-model="selectedArea"></v-select>
            <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  style="max-width: 250px;"
                  v-model="computedDateStartFormatted"
                  label="Fecha inicial"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateStart" @input="menu1 = false" locale="es" :max="dateEnd"></v-date-picker>
            </v-menu>

            <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ml-2"
                  style="max-width: 250px;"
                  v-model="computedDateEndFormatted"
                  label="Fecha inicial"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateEnd" @input="menu2 = false" locale="es" :min="dateStart"></v-date-picker>
            </v-menu>
          </v-toolbar>
          <v-divider class="primary"></v-divider>
          <div class="mt-2">
            <strong class="mr-4">Cantidad de tareas canceladas: {{ canceled }}</strong>
            <strong class="mr-4">Promedio para iniciar: {{ convertTime(countArea1 > 0 ? acumArea1 / countArea1 : 0) }}</strong>
            <strong>Promedio para finalizar: {{ convertTime(countArea2 > 0 ? acumArea2 / countArea2 : 0) }}</strong>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            sort-by="id"
            group-by="task"
            show-group-by
            :loading="loadingData"
          >
            <template v-slot:group.header="{items, isOpen, toggle}">
              <td colspan="1" class="text-start font-weight-medium">
                <v-btn icon @click="toggle">
                  <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                </v-btn>
                {{ items[0].task }}
              </td>
              <td colspan="1" class="text-right font-weight-medium">
                {{ convertTime(items[0].promTask1) }}
              </td>
              <td colspan="1" class="text-right font-weight-medium">
                {{ convertTime(items[0].promTask2) }}
              </td>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-start">{{ item.employee }}</td>
                <td class="text-right">{{ convertTime(item.prom1) }}</td>
                <td class="text-right">{{ convertTime(item.prom2) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apiTask from '@/api/task'
import { tipHeaders } from '@/constants/tableheaders'
import { format, parseISO } from 'date-fns'
import apiArea from '@/api/employeeArea'
export default {
  data: () => ({
    loadingData: false,
    total: 0,
    items: [],
    options: {},
    tipHeaders,
    menu1: false,
    menu2: false,
    dateStart: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    dateEnd: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    headers: [
      {
        text: 'Tareas',
        align: 'start',
        value: 'employee',
        groupable: false,
        sortable: false
      },
      { text: 'Tarea', value: 'task', align: 'right', sortable: false, groupable: false },
      { text: 'Promedio para iniciar', value: 'prom1', align: 'right', sortable: false, groupable: false },
      { text: 'Promedio para finalizar', value: 'prom2', align: 'right', sortable: false, groupable: false },
    ],
    areas: [],
    selectedArea: '',
    canceled: 0,
    countArea1: 0,
    acumArea1: 0,
    countArea2: 0,
    acumArea2: 0,
  }),
  computed: {
    computedDateStartFormatted () {
      return this.dateStart ? format(parseISO(this.dateStart), 'dd-MM-yyyy') : ''
    },
    computedDateEndFormatted () {
      return this.dateEnd ? format(parseISO(this.dateEnd), 'dd-MM-yyyy') : ''
    }
  },
  created () {
    this.loadArea()
  },
  methods: {
    async getDataFromApi () {
      this.loadingData = true
      const data = await this.loadTask()
      // this.total = data.total
      this.items = data
      this.loadingData = false
    },
    async loadTask () {
      try {
        const query = {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          area: this.selectedArea
        }
        const { data: { data } } = await apiTask.fetchDuration(query)
        console.log(data)
        const arrayAux = []
        this.canceled = 0
        data.forEach(element => {
          let promTask1 = 0
          let promTask2 = 0
          let countTask = 0
          element.employees.forEach(employee => {
            let acumTask = 0
            let acumTask2 = 0
            let count = 0
            employee.tasks.forEach(task => {
              if (task.status != 'cancel') {
                const date1 = new Date(task.transactions.find(t => t.status === 'process').createdAt)
                const date2 = new Date(task.dateStart)
                const diff = date1 > date2 ? date1 - date2 : (date2 - date1) * -1
                acumTask += diff
                const diff2 = (new Date(task.transactions.find(t => t.status === 'completed').createdAt) - new Date(task.transactions.find(t => t.status === 'process').createdAt))
                acumTask2 += diff2
                count ++
                countTask ++
                console.log(diff, 'acumTask')
                promTask1 += diff
                promTask2 += diff2
                this.countArea1 ++
                this.acumArea1 += diff
                this.countArea2 ++
                this.acumArea2 += diff2
              } else {
                this.canceled ++
              }
            })
            arrayAux.push({
              id: element._id + '-' + employee._id,
              task: element.name,
              employee: employee.employee.name + ' ' + employee.employee.lastName,
              prom1: count > 0 ? acumTask / count : 0,
              prom2: count > 0 ? acumTask2 / count : 0
            })
          })
          arrayAux.forEach(a => {
            if (a.id.split('-')[0] == element._id) {
              console.log(countTask, 'countTask')
              a.promTask1 = countTask > 0 ? promTask1 / countTask : 0
              a.promTask2 = countTask > 0 ? promTask2 / countTask : 0
            }
          })
        })
        console.log(arrayAux)
        // 56 minutos	
        return arrayAux
      } catch (err) {
        console.log(err);
        const msg = err.response.data?.err === 'No Permission' ? 'Acceso no autorizado' : null
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: msg || err.response.data.err || err.response.data.message })
        return { data: [], total: 0 }
      }
    },
    applyChange () {
      this.countArea1 = 0
      this.acumArea1 = 0
      this.countArea2 = 0
      this.acumArea = 0
      this.getDataFromApi()
    },
    async loadArea () {
      const { data: area } = await apiArea.list()
      this.areas = area.data
    },
    convertTime (time) {
      const isNegative = time < 0;
      const absMilliseconds = Math.abs(time);

      const hours = Math.floor(absMilliseconds / (1000 * 60 * 60))
      const remainingMilliseconds = absMilliseconds % (1000 * 60 * 60)
      const minutes = Math.floor(remainingMilliseconds / (1000 * 60))
      return `${isNegative ? '-' : ''}${hours} horas y ${minutes} minutos`
    },
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    selectedArea () {
      this.getDataFromApi()
    },
    dateStart () {
      if (this.selectedArea != '') {
        this.getDataFromApi()
      }
    },
    dateEnd () {
      if (this.selectedArea != '') {
        this.getDataFromApi()
      }
    }
  },
}
</script>
