
<template>
<div class="text-center">
  <v-card :loading="loading" class="elevation-0" style="height: 100%" max-height="300px">
    <Doughnut id="recaudo-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
    <h5>Ventas por medio de pago</h5>
</div>
</template>
  
<script>
  import apiOrder from "@/api/externos/orderPos";
  import { Doughnut } from 'vue-chartjs'
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  
ChartJS.register(ArcElement, Tooltip, Legend)

  export default {
    components: { Doughnut },
    props: {
      filters:{ Object },
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    watch: {
    },
    mounted() {
      this.getDataMetrics()
    },
    methods: {
     async getDataMetrics() {
        try {
          let dates = {}
          this.loading = true
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate
          }
          const {data: {data:dataOrder} } = await apiOrder.getPayGeneral(query)
          const newArray = Object.values(dataOrder.accumulateByPay)
          const total = newArray.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
          this.chartData = {
          labels:  newArray.map(e=>`${e.name} ${total > 0 ? ((e.total * 100) / total).toFixed(2) : 0}%`),
          datasets: [{
            backgroundColor: ['#a88e68', '#707070de', '#695339', '#bcaaa4', '#d5d5d5', '#726868' ],
            data: newArray.map(e=>e.total)
          }]
        }
          this.loading = false
        } catch (error) {
          console.log(error, "error")
        }
     },
    },
  }
  </script>