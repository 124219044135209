
<template>
  <v-card :loading="loading" class="elevation-0" style="height: 100%" max-height="300px">
    <Bar id="recaudo-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
</template>
  
<script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    components: { Bar },
    props: {
      business:{ type: Object, default: {}},
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: [
          { 
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    watch: {
    //   filters: {
    //   handler () {
    //     this.getData()
    //   },
    //   deep: true,
    // },
    business() {
      this.getData()
    }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.chartData = {
          labels:  Object.values(this.business).map(e=>e.name),
          datasets: [{
            label: 'Ventas por Unidad de Negocio',
            backgroundColor: '#a88e68',
            data: Object.values(this.business).map(e=>e.total)
          }]
        }
        this.loading = false
      }
    },
  }
  </script>