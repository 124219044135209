
<template>
  <v-card :loading="loading" class="elevation-0" style="height: 100%">
    <Bar id="naciomnalidades-chart" :options="chartOptions" :data="chartData"/>
  </v-card>
</template>
  
<script>
import moment from 'moment'
import api from '@/api/booking'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  components: { Bar },
  props: {
    filters: Object,
  },
  data: () => ({
    loading: true,
    chartData: {
      labels: [],
      datasets: [
        { 
          // label: 'Nacionalidades',
          data: [40, 30, 22, 20, 19, 16, 14, 11, 10, 9, 8, 7, 6, 5],
          // backgroundColor: ['#A88E68', '#eceff1'],
        },
      ],
    },
    chartOptions: {
      indexAxis: 'y',
      responsive: true
    },
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData(filters) {
      this.loading = true
      try {
        if (!filters) { filters = this.filters }
        const query = {
          ...this.options,
          isVirtual: filters.isVirtual,
          user: filters.usersSelected,
          status: filters.statusSelected,
          typology: filters.typology,
          place: filters.place,
          select: 'rates',
          populate: 'orders',
          dateStart: filters.checkinRange 
            ? filters.checkin ? [moment(filters.checkin[0]).format('YYYY-MM-DD'), moment(filters.checkin[1]).format('YYYY-MM-DD')] : null
            : filters.checkin ? moment(filters.checkin).format('YYYY-MM-DD') : null,
          dateEnd: filters.checkoutRange 
            ? filters.checkout ? [moment(filters.checkout[0]).format('YYYY-MM-DD'), moment(filters.checkout[1]).format('YYYY-MM-DD')] : null
            : filters.checkout ? moment(filters.checkout).format('YYYY-MM-DD') : null,
          createdAt: filters.createdAtRange 
            ? filters.createdAt ? [moment(filters.createdAt[0]).format('YYYY-MM-DD'), moment(filters.createdAt[1]).format('YYYY-MM-DD')] : null
            : filters.createdAt ? moment(filters.createdAt).format('YYYY-MM-DD') : null,
        }
        const { data } = await api.getRecaudoNationalities(query)
        console.log('data nationalities', data)
        this.chartData = {
          labels: data.map(d => d.nationality),
          datasets: [
            { 
              label: 'Nacionalidades',
              data: data.map(d => d.count),
              backgroundColor: ['#A88E68'],
            },
          ],
        },
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
  },
}
</script>