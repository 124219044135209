
<template>
  <v-card :loading="loading" class="elevation-0" style="height: 100%">
    <Bar id="chart" :options="chartOptions" :data="chartData"/>
  </v-card>
</template>
  
<script>
  import apiOrder from "@/api/externos/orderPos";
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    components: { Bar },
    props: {
      filters: Object,
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: [
          { 
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
      }
    }),
    mounted() {
      this.getDataMetrics()
    },
    methods: {
      async getDataMetrics() {
        try {
          let dates = {}
          console.log(this.filters,'this.filters');
          this.loading = true
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate
          }
          const {data: {data:dataOrder} } = await apiOrder.getMomentDay(query)
          this.chartData = {
          labels:  ['Mañana', 'Tarde', 'Noche'],
          datasets: [{
            label: 'Momento del día',
            backgroundColor: '#707070de',
            data: [dataOrder.morning, dataOrder.afternoon, dataOrder.evening]
          }]
        }
          this.loading = false
        } catch (error) {
          console.log(error, "error")
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicación con el servidor.', text: 'No se pudo establecer comunicación con servidor.' })
        }
      },
    },
  }
</script>