<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Empleados <v-icon>mdi-chevron-right</v-icon> KPI Tareas
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
      <v-col>
        <TaskTable ref="TaskTable" />
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import TaskTable from '@/components/Employee/Task/TaskTable.vue'
export default {
  components: { TaskTable },
  data: () => ({
    dataExcel: [],
    total: 0,
  }),
  methods: {
  },
}
</script>