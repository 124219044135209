<template>
  <v-container fluid>
    <v-toolbar color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Dashboard
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
      <v-col>
        <Dashboard/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard.vue' 
export default {
  components: { Dashboard },
}
</script>
