<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Servicios <v-icon>mdi-chevron-right</v-icon> Reporte de ingresos
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#servcieTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Servicios'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <ServiceOrder ref="serviceTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import ServiceOrder from '@/components/reports/service/ServiceOrder.vue'
import api from '@/api/serviceOrder'
export default {
  components: { ServiceOrder },
  data: () => ({
    dataExcel: [],
  }),
  created() {
  },
  methods: {
    async beforeDownload() {
      const query = {
        dateStart: this.$refs.serviceTable.dateStart,
        dateEnd: this.$refs.serviceTable.dateEnd
      }
      const { data } = await api.fetch(query)
      console.log(data)
      this.dataExcel = data.data.map(item => {
        return {
          servicio: item.service.service.name,
          cliente: item.user.name + ' ' + item.user.lastName,
          'sub total': item.subtotal,
          tax: item.taxAmount,
          total: item.total,
          status: item.status,
          empleado: item.employee ? item.employee.name + ' ' + item.employee.lastName: '',
          booking: item.booking?.ref ,
          descuento: item.discount?.amount,
          credito: item.credit,
          'método de pago': item.methodPay,
          fecha: moment(item.createdAt).format('DD/MM/yyyy hh:mm a')
        }
      })
      // console.log(this.$refs.serviceTable.items)
      // this.dataExcel = this.$refs.serviceTable.items.map(item => {
      //   return {
      //     service: item.service.service.name,
      //     user: item.user.name + ' ' + item.user.lastName,
      //     subtotal: item.subtotal,
      //     taxAmount: item.taxAmount,
      //     total: item.total,
      //     status: item.status,
      //     employee: item.employee ? item.employee.name + ' ' + item.employee.lastName: '',
      //     booking: item.booking?.ref ,
      //     methodPay: item.methodPay,
      //     createdAt: moment(item.createdAt).format('DD/MM/yyyy hh:mm a')
      //   }
      // })
    },
    openDrawer() {
      this.$refs.serviceTable.openDrawer()
    },
  },
}
</script>