<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          App <v-icon>mdi-chevron-right</v-icon> Reporte de tiempo en la app
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="tab == 'tab-1'">
        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="max-width: 200px;"
              :value="computedDateFormatted"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @change="menu1 = false" locale="es" :max="today"></v-date-picker>
        </v-menu>
      </template>
      <template v-else-if="tab == 'tab-2'">
        <v-menu ref="menu2" v-model="menu2" max-width="290px" :close-on-content-click="false" :return-value.sync="date2">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateFormatted2"
              style="max-width: 200px;"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" locale="es" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="saveDate2">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </template>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-tabs v-model="tab" centered>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            Usuarios
            <v-icon class="ml-2">mdi-account-clock</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Días
            <v-icon class="ml-2">mdi-clipboard-text-clock</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="w-100" ref="tabBar">
          <v-tab-item value="tab-1">
            <v-card>
              <v-card-text>
                <span>
                  <UserUseTime ref="UserUseTime" :date="date" :tabWidth="tabWidth" />
                </span>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat>
              <v-card-text>
                <span>
                  <UserUseTimeGroup ref="UserUseTimeGroup" :date="date2" :tabWidth="tabWidth" />
                </span>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UserUseTime from '@/components/Chart/UseTime/UserUseTime.vue'
import UserUseTimeGroup from '@/components/Chart/UseTime/UserUseTimeGroup.vue'
import { format, parseISO, subDays, startOfWeek, endOfWeek } from 'date-fns'
export default {
  components: { UserUseTime, UserUseTimeGroup },
  data: () => ({
    tabWidth: 0,
    menu1: false,
    menu2: false,
    date: format(parseISO(subDays(new Date(), 1).toISOString()), 'yyyy-MM-dd'),
    // date2: [format(parseISO(new Date().toISOString())), format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')],
    date2: [format(startOfWeek(new Date()), 'yyyy-MM-dd'), format(endOfWeek(new Date()), 'yyyy-MM-dd')],
    today: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    tab: null,
  }),
  computed: {
    computedDateFormatted () {
      return this.date ? format(parseISO(this.date), 'dd-MM-yyyy') : ''
    },
    computedDateFormatted2 () {
      return this.date2.join(' ~ ')
      // return this.date2 ? format(parseISO(this.date2), 'dd-MM-yyyy') : '' this.dates.join(' ~ ')
      // return this.date2 ? format(parseISO(this.date2), 'dd-MM-yyyy') : ''
    },
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize)
      // this.handleResize()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.tabWidth = this.$refs.tabBar.$el.offsetWidth
      console.log(this.tabWidth)
    },
    saveDate2 () {
      if (this.date2.length > 1 && new Date(this.date2[1]) - new Date(this.date2[0]) < 0) {
        this.date2 = [this.date2[1], this.date2[0]]
      }
      this.$nextTick(() => {
        this.$refs.menu2.save(this.date2)
        this.$refs.UserUseTimeGroup.loadData()
      })
    }
  }
}
</script>

<style>
.w-100 {
  width: 100%;
}
</style>
