<template>
  <div>
  <v-row>
    <v-col>
      <Sales ref="Sales" :initDate="initDate" :endDate="endDate" :chartDataLine="chartDataLine" />
    </v-col>
  </v-row>
  <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y búsquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Rango de fecha
                <v-spacer></v-spacer>
              </v-subheader>
              <v-dialog ref="modalCheckin" v-model="modalCheckin" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="rangeDate" readonly v-bind="attrs" v-on="on" hide-details dense clearable placeholder="26/09/2024"></v-text-field>
                </template>
                <v-date-picker range v-model="rangeDate" @input="handleDateInput"></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Negocio</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-store" v-model="businessSelected" @update:search-input="onSearchBusiness" :items="business" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Negocio" multiple clearable chips deletable-chips small-chips>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title >{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="height: 64px;"></div>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getData" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" @click="clean" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
  </div>
</template>

<script>
  import { PosPopularityHeaders } from '@/constants/tableheaders'
  import Sales from '@/components/Chart/UseTime/SalesPosApp.vue'
  import apiOrder from "@/api/externos/orderPos";
  import apiBusiness from '@/api/business'
  import moment from 'moment'
  import { setCurrencyFormat } from '@/utils/index'

  export default {
    components: { Sales },
    data: () => ({
      PosPopularityHeaders,
      totalOrder: 0,
      dataOrder: [],
      loading: false,
      options: {},
      rangeDate: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      drawerRight: false,
      modalCheckin: false,
      modalCheckout: false,
      businessSelected: [],
      business: [],
      chartDataLine: {
      labels: [],
      datasets: []
      },
      labelsHours: [
        '1:00 am',
        '2:00 am',
        '3:00 am',
        '4:00 am',
        '5:00 am',
        '6:00 am',
        '7:00 am',
        '8:00 am',
        '9:00 am',
        '10:00 am',
        '11:00 am',
        '12:00 pm',
        '1:00 pm',
        '2:00 pm',
        '3:00 pm',
        '4:00 pm',
        '5:00 pm',
        '6:00 pm',
        '7:00 pm',
        '8:00 pm',
        '9:00 pm',
        '10:00 pm',
        '11:00 pm',
        '12:00 am',
        ],
      }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    created() {
      this.getBusiness()
      this.getData()
    },
    methods: {
      handleDateInput(dates) {
        if (dates && dates.length === 2) this.modalCheckin = false
      },
      openDrawer() {
        this.drawerRight = true
      },
      clean() {
        this.rangeDate = []
        this.businessSelected = null
      },
      async getBusiness(search) {
        try {
          this.loading = true
          let { data: { data: business } } = await apiBusiness.table({ itemsPerPage: 500, page: 1, search })
          business = business.filter(e=> e.business && !e.business.service)
          const auxSelecteds = this.business.filter(v => this.businessSelected.some(e => v.value === e))
          // console.log(business, 'business');
          this.business = business.map(v => ({ text: v.name, value: v._id }))
          this.business = [...this.business, ...auxSelecteds]
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
        }
      },
      onSearchBusiness(search) {
        this.getBusiness(search)
      },
      async getData() {
        try {
          let dates = {}
          if (this.rangeDate.length > 1)
            dates = { initDate: this.rangeDate[0], endDate: this.rangeDate[1] }
          else if (this.rangeDate.length == 1)
            dates = {
              initDate: this.rangeDate[0],
              endDate: this.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate,
            business: this.businessSelected
          }
          const {data: dataOrder} = await apiOrder.getSalesApp(query)
          let nameBusiness = [] 
          this.businessSelected.forEach(e=>{
            const find = this.business.find(h=>h.value === e)
            if (find) nameBusiness.push(find.text)
          })
          nameBusiness = nameBusiness.join(', ')
          this.chartDataLine = {
            labels: this.labelsHours,
            datasets: [{
              label: nameBusiness.length === 0 ? 'Cantidad de ordenes generadas en todos los negocios' : `Cantidad de ordenes generadas en los negocios: ${nameBusiness}` ,
              backgroundColor: '#A88E68',
              data: Object.values(dataOrder.data)
            }]
        }
        } catch (error) {
          console.log(error, "error")
        }
      },
    },
    
  }
</script>