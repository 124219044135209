<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card :loading="loading" class="mx-auto my-12" max-width="500">
          <template slot="progress">
            <v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
          </template>
          <v-img height="250" src="https://i0.wp.com/juanpaz.net/wp-content/uploads/2023/11/Living-2.jpeg?resize=768%2C512&ssl=1"></v-img>
          <v-card-title> <span class="primary--text pr-2">Steii </span> | Login</v-card-title>
          <v-card-text>
            <div class="text-subtitle-1">
              Apartado de reportes generales y gerenciales.
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <v-text-field :error="errorLogin" v-model="user.email" prepend-icon="mdi-account" label="Email" placeholder="Placeholder" outlined></v-text-field>
            <v-text-field :error="errorLogin" @keyup.enter="login" v-model="user.password" prepend-icon="mdi-lock" type="password" label="Password" placeholder="Placeholder" outlined></v-text-field>
          </v-card-text>
          <v-card-actions class="primary">
            <v-btn block color="white" text @click="login">
              Ingresar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import api from '@/api/auth'
  export default {
    data: () => ({
      errorLogin: false,
      loading: false,
      user: {
        email: '',
        password: '',
      },
    }),
    methods: {
      async login () {
        try {
          this.loading = true
          const { data } = await api.employeeLogin(this.user)
          // console.log('data', data)
          const { token, employee } = data
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(employee))
          this.$router.push('/')
          this.errorLogin = false
          this.loading = false
        } catch (error) {
          this.errorLogin = true
          this.$notify({
            type: 'warn',
            group: 'noti',
            title: 'Error de autenticacion',
            text: 'Los datos no coinciden. Por favor intente de nuevo.',
          })
          this.loading = false
          console.log('error', error)
        }
      },
    },
  }
</script>