<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Eventos y Reservas <v-icon>mdi-chevron-right</v-icon> Reporte de eventos y reservas de espacios
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="event-legend white--text p-1 brown mr-2">Reservas de espacio</div>
      <div class="event-legend white--text p-1 deep-orange">Eventos</div>
    </v-toolbar>
    <v-row class="mt-2">
      <v-col>
        <EventCalendar ref="EventCalendar"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventCalendar from '@/components/reports/event/EventCalendar.vue'
export default {
  components: { EventCalendar },
  data: () => ({
  }),
  methods: {
  },
}
</script>

<style scoped>
.event-legend {
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  margin-bottom: 5px;
}
</style>