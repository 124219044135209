
<template>
<div class="text-center">
  <v-card class="elevation-0 text-center" style="height: 100%" max-height="300px">
    <h6>Por App</h6>
      <Pie
        :chart-options="chartOptions"
        :data="chartData"
      />
  </v-card>
    <h5 class="pt-3">Ventas por Steii</h5>
</div>
</template>
  
<script>
import { Line as LineChartGenerator, Pie } from 'vue-chartjs'
import {
Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, CategoryScale, PointElement, LinearScale, LineElement, ArcElement)
  
  export default {
    components: { LineChartGenerator, Pie },
    props: {
      styles: {
      type: Object,
      default: () => {}
    },
      acumDesktop: { type: Number, default: 0 },
      acumApp: { type: Number, default: 0 },
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    mounted() {
      this.getData()
    },
    watch: {
      acumDesktop() {
        this.getData()
      },
      acumApp() {
        this.getData()
      }
    },
    methods: {
      getData() {
        const total = this.acumDesktop + this.acumApp
        this.chartData = {
          labels: [`NO ${total > 0 ? ((this.acumDesktop * 100) / total).toFixed(2) : 0}%`, `SÍ ${total > 0 ? ((this.acumApp * 100) / total).toFixed(2) : 0}%`],
          datasets: [{
            backgroundColor: ['#a5a5a5', '#393836'],
            data: [this.acumDesktop, this.acumApp ]
          }]
        }
      }
    },
  }
  </script>