import axios from '../index'
const { VUE_APP_API_PAYME } = process.env
export default {
  getTodayTRM() {
    return axios.get(`${VUE_APP_API_PAYME}/api/v1/trm`)
  },
  askPayStatus(id) {
    return axios.post(`${VUE_APP_API_PAYME}/api/v1/pay_status?id=${id}&test=${VUE_APP_API_PAYMENT_TEST}`)
  },
}
