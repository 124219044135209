<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-data-table
          id="bookingTable"
          dense
          :headers="recaudoBookingHeaders"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          @click:row="openBookingDetail"
          :loading="loadingData"
          :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
          class="elevation-0 row-pointer no-wrap-table">
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status | toStatus }} 
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="bookinDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark class="elevation-0 secondary">
            <v-toolbar-title> <strong>{{ reference }}</strong></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn icon dark @click="bookinDetail = false">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <BookingDetail :reference="reference"/>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { setCurrencyFormat } from '@/utils/index'
import BookingDetail from '@/components/booking/BookingDetail.vue'
import moment from 'moment'
import api from '@/api/booking'
import { recaudoBookingHeaders } from '@/constants/tableheaders'

export default {
  components: { BookingDetail },
  props: { filters: Object },
  data: () => ({
    loading: true,
    loadingData: true,
    items: [],
    total: 0,
    options: {},
    recaudoBookingHeaders,
    reference: null,
    bookinDetail: false,
  }),
  methods: {
    async getBookings(filters) {
      try {
        if (!filters) { filters = this.filters }
        console.log('Inyected filters', filters)
        this.loadingData = true
        const query = {
          ...this.options,
          isVirtual: filters.isVirtual,
          user: filters.usersSelected,
          status: filters.statusSelected,
          typology: filters.typology,
          place: filters.place,
          select: 'rates',
          populate: 'orders',
          dateStart: filters.checkinRange 
            ? filters.checkin ? [moment(filters.checkin[0]).format('YYYY-MM-DD'), moment(filters.checkin[1]).format('YYYY-MM-DD')] : null
            : filters.checkin ? moment(filters.checkin).format('YYYY-MM-DD') : null,
          dateEnd: filters.checkoutRange 
            ? filters.checkout ? [moment(filters.checkout[0]).format('YYYY-MM-DD'), moment(filters.checkout[1]).format('YYYY-MM-DD')] : null
            : filters.checkout ? moment(filters.checkout).format('YYYY-MM-DD') : null,
          createdAt: filters.createdAtRange 
            ? filters.createdAt ? [moment(filters.createdAt[0]).format('YYYY-MM-DD'), moment(filters.createdAt[1]).format('YYYY-MM-DD')] : null
            : filters.createdAt ? moment(filters.createdAt).format('YYYY-MM-DD') : null,
        }
        const { data: { total, data: bookings} } = await api.table(query)
        console.log('bookings', bookings)
        this.total = total
        this.items = bookings.map(item => this.formatDataTable(item))
        console.log('bookings items', this.items)
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formatDataTable(item) {
      item.wayContactName = item.wayContactName ? item.wayContactName?.length < 12 ? item.wayContactName : `${item.wayContactName?.substring(0, 11)}...` : ''
      item.place.typology.name = item?.place?.typology?.name ? item?.place?.typology?.name?.length < 15 ? item?.place?.typology?.name : `${item?.place?.typology?.name?.substring(0, 11)}...` : ''
      item.place.name = item?.place?.name ? item?.place?.name?.length < 15 ? item?.place?.name : `${item?.place.name?.substring(0, 11)}...` : ''
      item.user.name = `${item.user.name.split(' ')[0]} ${item.user.lastName.split(' ')[0].length < 4 ? item.user.lastName : item.user.lastName.split(' ')[0]}`
      item.dateStart = moment.utc(item.dateStart).format('DD/MM/YYYY')
      item.dateEnd = moment.utc(item.dateEnd).format('DD/MM/YYYY')
      item.createdAt = moment.utc(item.createdAt).format('DD/MM/YYYY')
      item.isVirtual = item.isVirtual ? 'SI' : 'NO'
      item.total = setCurrencyFormat(item.total)
      item.ratesByMonth = this.getRateNightsByMonth(item.rates, this.monthSelected)
      item.monthAmount = setCurrencyFormat(item.ratesByMonth.reduce((a, b) => Number(a) + Number(b.totalRate), 0))
      item.bookingNights = item.ratesByMonth.length
      item.ordersByMonth = this.getOrdersByMonth(item.orders, this.monthSelected)
      item.roomServices = setCurrencyFormat(item.ordersByMonth.reduce((a, b) => Number(a) + Number(b.total), 0))
      // console.log('item.rates', item.rates)
      return item
    },
    openBookingDetail(row) {
      console.log('clicked row', row)
      this.reference = row.ref
      this.bookinDetail = true
    },
    getRateNightsByMonth(rates, monthSelected) {
      const yearAndMonth = moment.utc(monthSelected).format('YYYY-MM')
      return rates.filter(rate => {
        const date = rate.date.split('-')
        return yearAndMonth === `${date[0]}-${date[1]}`
      })
    },
    getOrdersByMonth(orders, monthSelected) {
      const yearAndMonth = moment.utc(monthSelected).format('YYYY-MM')
      return orders.filter(order => {
        const date = moment.utc(order.createdAt).format('YYYY-MM-DD').split('-')
        return yearAndMonth === `${date[0]}-${date[1]}` && !order.isForLodging
      })
    },
  },
  watch: {
    options: {
      handler () {
        this.getBookings()
      },
      deep: true,
    },
  },
}
</script>

