<template>
  <v-row>
    <v-col>
      <v-data-table
        :headers="PosDetailsHeaders"
        :items="dataOrder"
        :options.sync="options"
        :server-items-length="totalOrder"
        :loading="loading"
        class="elevation-0"
      ></v-data-table>
    </v-col>
    <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y búsquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Rango de fecha
                <v-spacer></v-spacer>
              </v-subheader>
              <v-dialog ref="modalCheckin" v-model="modalCheckin" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="rangeDate" readonly v-bind="attrs" v-on="on" hide-details dense clearable placeholder="26/09/2024"></v-text-field>
                </template>
                <v-date-picker range v-model="rangeDate" @input="handleDateInput"></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Negocio</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-store" v-model="businessSelected" @update:search-input="onSearchBusiness" :items="business" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Negocio" multiple clearable chips deletable-chips small-chips>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Evento</v-subheader>
              <v-autocomplete :loading="loading" no-filter prepend-icon="mdi-calendar-star" v-model="eventSelected" @update:search-input="onSearchBusiness" :items="eventOptions" persistent-hint :persistent-placeholder="true" hide-details dense placeholder="Evento" clearable>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="height: 64px;"></div>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getDataTable" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" @click="clean" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
  </v-row>
</template>

<script>
  import { PosDetailsHeaders } from '@/constants/tableheaders'
  import apiOrder from "@/api/externos/orderPos";
  import apiBusiness from '@/api/business'
  import moment from 'moment'
  import { setCurrencyFormat } from '@/utils/index'

  export default {
    data: () => ({
      PosDetailsHeaders,
      totalOrder: 0,
      dataOrder: [],
      loading: false,
      options: {},
      rangeDate: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      drawerRight: false,
      modalCheckin: false,
      businessSelected: [],
      business: [],
      eventSelected: null,
      eventOptions: [{text: 'Sí', value: true}, {text: 'No', value: false}],
      }),
    watch: {
      options: {
        handler () {
          this.getDataTable()
        },
        deep: true,
      },
    },
    created() {
      this.getBusiness()
    },
    methods: {
      handleDateInput(dates) {
        if (dates && dates.length === 2) {
          // this.$refs.modalDateStart.save(dates);
          this.modalCheckin = false
        }
      },
      //obtener datos para tabla
    async getDataTable() {
      try {
        let dates = {}
        this.dataOrder = []
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (this.rangeDate.length > 1)
          dates = { initDate: this.rangeDate[0], endDate: this.rangeDate[1] }
        else if (this.rangeDate.length == 1)
          dates = {
            initDate: this.rangeDate[0],
            endDate: this.rangeDate[0],
          }
        const direction = sortDesc[0] ? "asc" : "desc"
        let field = sortBy[0]
        if (sortBy[0] == "price") field = "unitPrice"
        else if (sortBy[0] == "taxAmount") field = "tax"
        else if (sortBy[0] == "couponAmount") field = "discount.amount"
        else if (sortBy[0] == "coupon") field = "discount.coupon"
        const query = {
          sort:
            sortBy.length > 0
              ? [
                  {
                    direction: direction,
                    field: field,
                    sortField: field,
                  },
                ]
              : null,
          page: page,
          per_page: itemsPerPage,
          initDate: dates.initDate,
          endDate: dates.endDate,
          business: this.businessSelected,
          event: this.eventSelected
        }
        this.loading = true
        const {data: dataOrder} = await apiOrder.getDetailSales(query)
        // console.log(dataOrder,'dataOrder');
        this.dataOrder = dataOrder.data

        this.dataOrder.forEach((j) => {
          j.createdAt = moment(j.createdAt).format('YYYY-MM-DD hh:mm a')
          j.gift = j.gift ? "Sí" : "No"
          j.coupon = j.discount ? j.discount.coupon : "No"
          j.couponAmount = j.discount ? setCurrencyFormat(-j.discount.amount) : setCurrencyFormat(0)
          j.taxAmount = setCurrencyFormat(j.tax) ?? setCurrencyFormat(0)
          j.price = setCurrencyFormat(j.unitPrice)
          j.order.event = j.order.event ? "Sí" : "No"
        })
        this.totalOrder = dataOrder.links.pagination.total
        this.loading = false
      } catch (error) {
        console.log(error, "error")
        this.loading = false
      }
    },
    openDrawer() {
      this.drawerRight = true
    },
    clean() {
      this.rangeDate = []
      this.businessSelected = null
    },
    async getBusiness(search) {
      try {
        this.loading = true
        let { data: { data: business } } = await apiBusiness.table({ itemsPerPage: 500, page: 1, search })
        business = business.filter(e=>e.business && !e.business.service)
        const auxSelecteds = this.business.filter(v => this.businessSelected.some(e => v.value === e))
        // console.log(business, 'business');
        this.business = business.map(v => ({ text: v.name, value: v._id }))
        this.business = [...this.business, ...auxSelecteds]
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    onSearchBusiness(search) {
      this.getBusiness(search)
    },
    },
    
  }
</script>