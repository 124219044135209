<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <!-- <v-toolbar flat color="transparent">
            <v-toolbar-title class="primary--text">Huespedes y Visitantes registrados</v-toolbar-title>
            <v-spacer></v-spacer> 
          </v-toolbar> -->
          <!-- <v-divider class="primary"></v-divider> -->
          <v-tabs fixed-tabs v-model="tab" class="mt-5">
            <v-tab><v-icon class="pr-2">mdi-account-group</v-icon>Huespedes</v-tab>
            <v-tab><v-icon class="pr-2">mdi-account-group</v-icon>Visitantes</v-tab>
            <v-tab-item>
              <v-data-table
                id="ratesTable"
                dense
                :headers="userHeaders"
                :items="guests"
                :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
                :options.sync="options"
                class="elevation-0 mt-5">
              </v-data-table>
              <v-divider class="primary mt-5"></v-divider>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                id="ratesTable"
                dense
                :headers="userHeaders"
                :items="visitors"
                :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
                :options.sync="options"
                class="elevation-0 mt-5">
              </v-data-table>
              <v-divider class="primary mt-5"></v-divider>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import api from '@/api/booking'
import { userHeaders } from '@/constants/tableheaders'
export default {
  props: { 
    reference: String,
  },
  data: () => ({
    tab: null,
    loading: true,
    userHeaders,
    guests: [],
    visitors: [],
    options: {},
  }),
  mounted() {
    this.getGuestsAndVisitors()
  },
  methods: {
    async getGuestsAndVisitors() {
      try {
        const { data: { guests, visitors } } = await api.getBookingGuestsAndVisitorsByRef(this.reference)
        // console.log('data guests', guests, visitors)
        this.guests = guests.map(data => this.formatDataTable(data))
        this.visitors = visitors.map(data => this.formatDataTable(data))
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formatDataTable(data) {
      data.name = `${data.name} ${data.lastName}`
      data.category = data.category.toUpperCase()
      return data
    },
  },
  watch: {
    reference() {
      this.getGuestsAndVisitors()
    }
  },
}
</script>