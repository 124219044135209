<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :loading="loading" class="elevation-0">
          <v-card-text>
            <v-row>
              <v-col cols="5">
                Creado: {{ client.createdAt | toDateUTC }} <br>
                Ultima actualizacion: {{ client.updatedAt | toDateUTC }} <br>
              </v-col>
              <v-col class="text-center" cols="2">
                <v-avatar class="profile" color="grey lighten-3" size="150" rounded>
                  <v-img :src="`http://44.211.71.232/images/lg/${client.photo}`"></v-img>
                </v-avatar>
              </v-col cols="5">
              <v-col class="text-right text-h6">
                <span>Estado: </span>
                <span :class="{ 'green--text': !client.deleted, 'red--text': client.deleted }">{{ client.deleted ? 'Retirado' : 'Activo' }}</span>
                <span> Categoria: </span>
                <span class="text-capitalize primary--text">{{ client.category }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <span class="text-h4">{{ client.name }} {{ client.lastName }}</span>
                <v-divider class="my-5 primary text-center" style="width: 100px; margin: auto;"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <table class="text-center" style="width: 100%; min-height: 170px;">
                  <tr>
                    <td>
                      <span class="primary--text">Email: </span>{{ client.email }}
                    </td>
                    <td>
                      <span class="primary--text">Telefono: </span>{{ client.phone }}
                    </td>
                    <td>
                      <span class="primary--text">Cumpleaños: </span>{{ client.birthday | toDateUTC }}
                    </td>
                    <td>
                      <span class="primary--text">Nacionalidad: </span>{{ client.nationality }}
                    </td>
                    <td>
                      <span class="primary--text">Sexo: </span>{{ client.gender }}
                    </td>
                  </tr>
                  <tr class="mt-5">
                    <td>
                      <span class="primary--text">Pais: </span>{{ client.country }}
                    </td>
                    <td>
                      <span class="primary--text">Estado: </span>{{ client.state }}
                    </td>
                    <td>
                      <span class="primary--text">Ciudad: </span>{{ client.city }}
                    </td>
                    <td>
                      <span class="primary--text">ID: </span>{{ client.documentID }}
                    </td>
                    <td>
                      <span class="primary--text">Tipo de ID: </span>{{ client.documentType?.toUpperCase() }}
                    </td>
                  </tr>
                  <tr class="mt-5">
                    <td>
                      <span class="primary--text">ZIP code: </span>{{ client.zipcode }}
                    </td>
                    <td>
                      <span class="primary--text">Direccion: </span>{{ client.address }}
                    </td>
                    <td>
                      <span class="primary--text">Es menor: </span>{{ client.isUnderage ? 'SI' : 'NO' }}
                    </td>
                    <td>
                      <span class="primary--text">Es empresa: </span>{{ client.isCompany }}
                    </td>
                    <td>
                      <span class="primary--text">Master ID: </span>{{ client.masterID }}
                    </td>
                  </tr>
                  <tr class="mt-5">
                    <td>
                      <span class="primary--text">Detalle de direccion: </span>{{ client.addressDetail }}
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tabs fixed-tabs v-model="tab">
                  <v-tab active><v-icon class="pr-2">mdi-account-details</v-icon>Extra info</v-tab>
                  <v-tab><v-icon class="pr-2">mdi-draw-pen</v-icon>Firma</v-tab>
                  <v-tab><v-icon class="pr-2">mdi-cellphone</v-icon>App info</v-tab>
                  <v-tab><v-icon class="pr-2">mdi-book</v-icon>Reservas</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-row>
                      <v-col class="text-center" v-if="!client.extraData">
                        <div class="mt-10 py-10">
                          <span class="text-h6">Sin datos extras</span>
                        </div>
                      </v-col>
                      <v-col class="text-center" v-else>
                        <table class="text-center mt-10" style="width: 100%; min-height: 170px;">
                          <tr>
                            <td>
                              <span class="primary--text">Estado Civil: </span>{{ client.extraData.maritalStatus }}
                            </td>
                            <td>
                              <span class="primary--text">Idioma: </span>{{ client.extraData.spokenLanguages }}
                            </td>
                            <td>
                              <span class="primary--text">Profesion: </span>{{ client.extraData.professionalApproach }}
                            </td>
                            <td>
                              <span class="primary--text">Titulo: </span>{{ client.extraData.professionalTitle }}
                            </td>
                            <td>
                              <span class="primary--text">Ingreso: </span>{{ client.extraData.annualIncome }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="primary--text">Empresa: </span>{{ client.extraData.currentCompany }}
                            </td>
                            <td>
                              <span class="primary--text">Interes: </span>{{ client.extraData.activitiesOfInterest }}
                            </td>
                            <td>
                              <span class="primary--text">Agrupacion: </span>{{ client.extraData.populationGroupPreference }}
                            </td>
                            <td>
                              <span class="primary--text">Proposito: </span>{{ client.extraData.purposeOfTravel }}
                            </td>
                            <td>
                              <span class="primary--text">Referencia: </span>{{ client.extraData.howDidYouHearAbout }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="primary--text">Social Media: </span>{{ client.extraData.socialMediaProfile }}
                            </td>
                            <td>
                              <span class="primary--text">Traslado: </span>{{ client.extraData.transportationSystem }}
                            </td>
                            <td>
                              <span class="primary--text">Linkedin: </span>{{ client.extraData.linkedInProfile }}
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="text-center mt-5">
                    <v-row>
                      <v-col>
                        <v-avatar class="profile" color="grey lighten-3" size="400" rounded>
                          <v-img :src="client.signature" size="150"></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-col class="text-center" v-if="!client.masterID">
                      <div class="mt-10 py-10">
                        <span class="text-h6">Usuario no asociado a APP</span>
                      </div>
                    </v-col>
                    <v-col v-else>
                      <table class="text-center" style="width: 100%; min-height: 170px;">
                        <tr>
                          <td>
                            <span class="primary--text">Email: </span>{{ master?.email }}
                          </td>
                          <td>
                            <span class="primary--text">Telefono: </span>{{ master?.phone }}
                          </td>
                          <td>
                            <span class="primary--text">Cumpleaños: </span>{{ master?.birthday | toDateUTC }}
                          </td>
                          <td>
                            <span class="primary--text">Nacionalidad: </span>{{ master?.nationality }}
                          </td>
                          <td>
                            <span class="primary--text">Sexo: </span>{{ master?.gender }}
                          </td>
                        </tr>
                        <tr class="mt-5">
                          <td>
                            <span class="primary--text">Pais: </span>{{ master?.country }}
                          </td>
                          <td>
                            <span class="primary--text">Estado: </span>{{ master?.state }}
                          </td>
                          <td>
                            <span class="primary--text">Ciudad: </span>{{ master?.city }}
                          </td>
                          <td>
                            <span class="primary--text">ID: </span>{{ master?.documentID }}
                          </td>
                          <td>
                            <span class="primary--text">Tipo de ID: </span>{{ master?.documentType?.toUpperCase() }}
                          </td>
                        </tr>
                        <tr class="mt-5">
                          <td>
                            <span class="primary--text">ZIP code: </span>{{ master?.zipcode }}
                          </td>
                          <td>
                            <span class="primary--text">Direccion: </span>{{ master?.address }}
                          </td>
                          <td>
                            <span class="primary--text">Es menor: </span>{{ master?.isUnderage ? 'SI' : 'NO' }}
                          </td>
                          <td>
                            <span class="primary--text">Es empresa: </span>{{ master?.isCompany }}
                          </td>
                          <td>
                            <span class="primary--text">Nombre: </span>{{ master?.name }} {{ master?.lastName }}
                          </td>
                        </tr>
                        <tr class="mt-5">
                          <td>
                            <span class="primary--text">Detalle de direccion: </span>{{ master?.addressDetail }}
                          </td>
                        </tr>
                      </table>
                      <!-- <pre>
                        {{ master }}
                      </pre> -->
                    </v-col>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col>
                        <v-data-table
                          id="clientBookingTable"
                          dense
                          :headers="bookingHeaders"
                          :items="bookings"
                          class="elevation-0 no-wrap-table mt-10">
                        </v-data-table>
                        <!-- <pre>
                          {{ bookings }}
                        </pre> -->
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <pre>
            {{ client }}
          </pre> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setCurrencyFormat } from '@/utils/index'
import { bookingHeaders } from '@/constants/tableheaders'
import moment from 'moment'
import api from '@/api/user'
import apiBooking from '@/api/booking'
import apiMaster from '@/api/externos/master'
export default {
  props: {
    clientID: String,
  },
  data: () => ({
    bookingHeaders,
    loading: true,
    client: {},
    tab: null,
    master: null,
    bookings: [],
  }),
  mounted() {
    this.getClientDetail()
  },
  methods: {
    async getClientDetail() {
      try {
        this.loading = true
        const { data: client } = await api.getOneByID(this.clientID)
        this.client = client
        const { data: { data: bookings } } = await apiBooking.table({ user: client._id })
        this.bookings = bookings.map(item => this.formatDataTable(item))
        if (client.masterID) {
          const { data: master } = await apiMaster.getUserAPPInfo(client.masterID)
          this.master = master
        } else {
          this.master = null
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({
          type: 'error',
          group: 'noti',
          title: 'Error con comunicacion con el servidor.',
          text: 'No se pudo establecer comunicacion con servidor.',
        })
      }
    },
    formatDataTable(item) {
      item.wayContactName = item.wayContactName ? item.wayContactName?.length < 12 ? item.wayContactName : `${item.wayContactName?.substring(0, 11)}...` : ''
      item.place.typology.name = item?.place?.typology?.name ? item?.place?.typology?.name?.length < 15 ? item?.place?.typology?.name : `${item?.place?.typology?.name?.substring(0, 11)}...` : ''
      item.place.name = item?.place?.name ? item?.place?.name?.length < 15 ? item?.place?.name : `${item?.place.name?.substring(0, 11)}...` : ''
      item.user.name = `${item.user.name.split(' ')[0]} ${item.user.lastName.split(' ')[0].length < 4 ? item.user.lastName : item.user.lastName.split(' ')[0]}`
      item.dateStart = moment.utc(item.dateStart).format('DD/MM/YYYY')
      item.dateEnd = moment.utc(item.dateEnd).format('DD/MM/YYYY')
      item.createdAt = moment.utc(item.createdAt).format('DD/MM/YYYY')
      item.isVirtual = item.isVirtual ? 'SI' : 'NO'
      item.total = setCurrencyFormat(item.total)
      return item
    },
  },
  watch: {
    clientID() {
      this.getClientDetail()
    },
  },
}
</script>