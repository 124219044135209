export const status = [
  {
    text: 'En progreso',
    value: 'in_progress',
    icon: 'mdi-checkbox-intermediate',
  },
  {
    text: 'Confirmado',
    value: 'confirmed',
    icon: 'mdi-check',
  },
  {
    text: 'No confirmado',
    value: 'not_confirmed',
    icon: 'mdi-checkbox-blank-outline',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    icon: 'mdi-cancel',
  },
  {
    text: 'Check In',
    value: 'checked_in',
    icon: 'mdi-check-all',
  },
  {
    text: 'Check Out',
    value: 'checked_out',
    icon: 'mdi-bag-suitcase',
  },
  {
    text: 'Not Show',
    value: 'no_show',
    icon: 'mdi-ghost',
  },
]