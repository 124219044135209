<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          POS <v-icon>mdi-chevron-right</v-icon>Ventas por App
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <OrderTable ref="posTable"/>
  </v-container>
</template>

<script>
import OrderTable from '@/components/reports/pos/SalesApp.vue'
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    openDrawer() {
      this.$refs.posTable.openDrawer()
    },
  },
}
</script>