<template>
  <v-app>
    <Aside/>
    <v-app-bar elevation="0" app>
      <div class="d-flex align-center">
        <img width="20%" :src="logo"/>
        <span class="pl-2 mx-5">|</span>
        <span>Reportes</span>
      </div>
      <v-spacer></v-spacer>
      <span class="primary--text font-weight-bold">TRM</span>
      <!-- <v-badge color="transparent primary--text" bottom left overlap :content="'TRM'"> -->
        <v-icon left color="primary">mdi-finance</v-icon>
      <!-- </v-badge> -->
      <span class="grey--text text-h5 ml-2 mr-5">{{ trm | toCurrency }}</span>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <notifications group="noti" />
  </v-app>
</template>

<script>
import apiPayme from '@/api/externos/payme'
import logo from './assets/logo.svg'
import Aside from '@/components/shared/Aside.vue';
export default {
  components: { Aside },
  data: () => ({
    trm: 0,
    dialog: false,
    logo,
  }),
  mounted() {
    this.getTRM()
  },
  methods: {
    async getTRM() {
      try {
        const { data: { data } } = await apiPayme.getTodayTRM()
        // console.log('data payme', data)
        this.trm = Number(data.value_30)
      } catch (error) {
        this.loading = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor TRM.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
  },
}
</script>

<style>
.v-data-table__wrapper tbody tr:nth-child(odd) {
  background-color: #FAFAFA;
}
.v-data-table__wrapper td {
  border-bottom: none !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)  {
  background: #A88E68 !important;
  color: white;
}
.row-pointer tbody tr:hover:not(.v-data-table__empty-wrapper) {
  cursor: pointer;
}
.no-wrap-table td {
  white-space: nowrap;
}
/* .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid #A88E68 !important;
} */
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}
.v-chip--removable {
  margin-top: 5px !important;
}
.v-select.v-select--chips.v-input--dense .v-select__selections {
  min-height: auto !important;
}
.no-show-hover:hover {
  background: white !important;
  color: black;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
