<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          POS <v-icon>mdi-chevron-right</v-icon> Detalles de ventas
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#posTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'POS-SALES-DETAIL'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <OrderTable ref="posTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderTable from '@/components/reports/pos/DetailSales.vue'
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    beforeDownload() {
      this.dataExcel = this.mapOrders(this.$refs.posTable.dataOrder)
    },
    openDrawer() {
      this.$refs.posTable.openDrawer()
    },
    mapOrders(data) {
      const body = data.map(e=>{
        return {
          "Orden N°": e.order.invoice,
          "Negocio": e.business?.name,
          "Nombre": e.item.name,
          "Cantidad": e.qty,
          "Precio unidad": e.unitPrice.toString().replace('.', ','),
          "Cupón": e.coupon,
          "Cupón Monto": e.discount?.amount?.toString().replace('.', ',') ?? 0,
          "Tipo impuesto": e.item.taxType,
          "Impuesto unidad": e.tax ? e.tax?.toFixed(2)?.toString().replace('.', ',') : 0,
          "Monto por adición": e.extras?.toString().replace('.', ','),
          "Cortesía": e.gift,
          "Fecha de creación": e.createdAt,
          "Nota": e.note,
        }
      })
      return body
    }
  },
}
</script>