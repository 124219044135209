<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-book</v-icon>
            <v-toolbar-title class="primary--text">Reviews de {{ title }}</v-toolbar-title>
              <div class="ml-2 primary--text mt-1">
                {{ computedDateStartFormatted }} - {{ computedDateEndFormatted }}
              </div>
            <v-divider class="primary mx-7"></v-divider>
          </v-toolbar>
          <v-data-table
            dense
            :loading="loading"
            :headers="reviewHeaders"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :single-expand="true"
            show-expand
            item-key="_id"
            :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
            class="elevation-0 mt-2 no-wrap-table"
          >
            <template v-slot:item.relatedData="{ item }">
              <template v-if="item.type == 'community'">{{ item.relatedData.name }} {{ item.relatedData.lastName }}</template>
              <template v-else-if="item.type == 'employee'">{{ item.relatedData?.name }} {{ item.relatedData?.lastName }}</template>
              <template v-else-if="item.type == 'service'">{{ item.relatedData.service.name }} - {{ item.relatedData.employee?.name }} {{ item.relatedData.employee?.lastName }}</template>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="py-3">
                <div class="d-flex justify-center pb-2">
                  <span class="text-h6">
                    Review
                  </span>
                  <v-icon left color="primary">mdi-star</v-icon>
                </div>
                <div class="d-flex w-100 justify-space-between">
                  <span v-for="(value, index) in item.values" :key="index" class="mr-3">
                    <span class="text--secondary">● {{ value.name }}:</span> {{ value.value }}
                  </span>
                </div>
              </td>
            </template>
            <template v-slot:item.user="{ item }">
              {{ item.user?.name }} {{ item.user?.lastName }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ dateFormat(item.createdAt) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateStartFormatted"
                    clearable
                    label="Inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dateStart = null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateStart" @change="menu1 = false" locale="es" :max="dateEnd"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateEndFormatted"
                    clearable
                    label="Fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dateEnd = null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateEnd" @change="menu2 = false" locale="es" :min="dateStart"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn class="elevation-0 primary--text" text @click="applyChange">Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment'
  import api from '@/api/review'
  import { reviewHeaders } from '@/constants/tableheaders'

  export default {
    props: ['type', 'title'],
    data: () => ({
      reviewHeaders,
      items: [],
      options: {},
      total: 0,
      loading: false,
      drawerRight: false,
      dateStart: moment().startOf('month').format('yyyy-MM-DD'),
      dateEnd: moment().endOf('month').format('yyyy-MM-DD'),
      menu1: false,
      menu2: false,
    }),
    computed: {
      computedDateStartFormatted () {
        return this.dateStart ? moment(this.dateStart).format('DD-MM-yyyy') : ''
      },
      computedDateEndFormatted () {
        return this.dateEnd ? moment(this.dateEnd).format('DD-MM-yyyy') : ''
      },
    },
    mounted() {
    },
    methods: {
      openDrawer () {
        this.drawerRight = true
      },
      applyChange () {
        this.getDataFromApi()
        this.drawerRight = false
      },
      async getDataFromApi () {
        this.loading = true
        const data = await this.getData()
        this.total = data.total
        this.items = data.data
        this.loading = false
      },
      async getData () {
        try {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          const direction = sortDesc[0] ? 'asc' : 'desc'
          console.log(this.type)
          const query = {
            ...sortBy[0] ? { sort: sortBy[0] + '|' + direction } : '',
            page: page,
            per_page: itemsPerPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd
          }
          console.log(query)
          const { data } = await api.fetchPaginate(this.type, query)
          console.log('service', data)
          return data
        } catch (error) {
          this.loading = false
          console.log('error', error)
          this.$notify({
            type: 'error',
            group: 'noti',
            title: 'Error con comunicacion con el servidor.',
            text: 'No se pudo establecer comunicacion con servidor.',
          })
        }
      },
      dateFormat (date) {
        return moment(date).format('DD/MM/yyyy hh:mm a')
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true
      },
      type: {
        immediate: true,
        handler(newType, oldType) {
          if (oldType) this.getDataFromApi()
        }
      }
    },
  }
</script>
