<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          Movimientos <v-icon>mdi-chevron-right</v-icon> Reporte de Tickets de Eventos
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <download-excel :name="'Movimientos Tickets de Eventos'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <OrderTable ref="orderTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderTable from '@/components/event/qr/OrderTable'
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    async beforeDownload() {
      this.dataExcel = await this.$refs.orderTable.getAllItems()
      this.dataExcel = this.dataExcel.map(item => this.formatExcelData(item))
    },
    formatExcelData(item) {
      item.cancelation_note = item.reason_cancellation
      delete item._id
      delete item.__v
      delete item.updatedAt
      delete item.deleted
      delete item.employee
      delete item.event_QR
      delete item.isCanceled
      delete item.items
      delete item.booking
      delete item.payment_source_id	
      delete item.datafone_voucher	
      delete item.datafone_card_type	
      delete item.datafone_amount	
      delete item.transfer_amount
      return item
    },
    openDrawer() {
      this.$refs.orderTable.openDrawer()
    },
  },
}
</script>