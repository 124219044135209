<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          POS <v-icon>mdi-chevron-right</v-icon> Reporte de ventas General
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#posTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'POS-SALES'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <OrderTable ref="posTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderTable from '@/components/reports/pos/SalesTable.vue'
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    beforeDownload() {
      this.dataExcel = this.mapOrders(this.$refs.posTable.dataOrder)
    },
    openDrawer() {
      this.$refs.posTable.openDrawer()
    },
    mapOrders(data) {
      const body = data.map(e=>{
        return {
          "Orden N°": e.invoice,
        "Negocio": e.business?.name,
        "Cliente": e.client,
        "Empleado": e.employeeName,
        "Orden cerrada por:": e.closedBy ? `${e.closedBy.name} ${e.closedBy.lastName}`: "",
        "Fecha de creación": e.createdAt,
        "Hora de creación": e.createdHour,
        "Fecha de cierre": e.updatedAt,
        "Hora de cierre": e.updatedHour,
        "Subtotal": ((Number(e.total) + Number(e?.discount?.amount ?? 0) -
         Number(e.tipAmount ?? 0) - Number(e.taxAmount ?? 0)).toFixed(2)).replace('.', ','),
        "Impuestos": e.taxAmount?.toString().replace('.', ','),
        "Descuento": e.discount?.amount?.toString().replace('.', ','),
        "% Desc": Number(e.percDiscount),
        "Propina": e.tipAmount?.toString().replace('.', ','),
        "Total": e.total?.toString().replace('.', ','),
        "Método de pago": e?.methodPay?.method,
        "N° de Personas": e.personOrder,
        "Nota": e.note,
        "REF Reserva": e.booking?.ref,
        "Dueño Reserva": e.booking?.user ? `${e.booking?.user?.name} ${e.booking?.user?.lastName}`: null,
        "Por app": e.byClientApp ? "SÍ" : "NO",
        }
      })
      return body
    }
  },
}
</script>