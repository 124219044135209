<template>
  <v-row>
    <v-col>
      <v-card class="elevation-0">
        <v-card-text>
          <v-toolbar dense flat color="transparent">
            <v-icon left color="primary">mdi-account-multiple</v-icon>
            <v-toolbar-title class="primary--text">Clientes</v-toolbar-title>
            <v-divider class="primary mx-7"></v-divider>
          </v-toolbar>
          <v-data-table
            id="bookingTable"
            dense
            :headers="clientHeaders"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            @click:row="openClientDetail"
            :loading="loadingData"
            :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
            class="elevation-0 row-pointer no-wrap-table mt-5">
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status | toStatus }} 
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-dialog v-model="clientDetail" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark class="elevation-0 secondary">
            <v-toolbar-title><strong>{{ clientSelected.name }}</strong></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn icon dark @click="clientDetail = false">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <ClientDetail :clientID="clientSelected._id"/>
        </v-card>
      </v-dialog>
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="400" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Busqueda rapida</v-subheader>
              <v-text-field prepend-icon="mdi-magnify" v-model="search" hide-details label="Search" single-line dense placeholder="Buscar por palabra clave"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">Categoria</v-subheader>
              <v-select prepend-icon="mdi-badge-account-outline" v-model="category" :items="categories" hide-details dense placeholder="Todos"></v-select>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getClients" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text>Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import ClientDetail from '@/components/client/ClientDetail'
import moment from 'moment'
import api from '@/api/user'
import { clientHeaders } from '@/constants/tableheaders'

export default {
  components: { ClientDetail },
  data: () => ({
    clientDetail: false,
    loading: true,
    loadingData: true,
    total: 0,
    items: [],
    options: {},
    clientHeaders,
    drawerRight: false,
    search: '',
    category: null,
    categories: [
      { text: 'Cliente', value: 'cliente' }, 
      { text: 'Empleado', value: 'empleado' }, 
      { text: 'Socio', value: 'socio' }, 
      { text: 'Miembro', value: 'miembro' },
      { text: 'Huesped', value: 'huesped' },
    ],
    clientSelected: {},
  }),
  methods: {
    async getClients() {
      try {
        this.loadingData = true
        const query = {
          ...this.options,
          search: this.search,
          category: this.category,
        }
        const { data: { total, data: clients} } = await api.table(query)
        console.log('clients', clients)
        this.total = total
        this.items = clients.map(item => this.formatDataTable(item))
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        console.log('error', error)
        this.$notify({ type: 'error', group: 'noti', title: 'Error con comunicacion con el servidor.', text: 'No se pudo establecer comunicacion con servidor.' })
      }
    },
    formatDataTable(item) {
      item.name = `${item.name.split(' ')[0]} ${item.lastName.split(' ')[0]}`
      item.category = item.category.toUpperCase()
      item.createdAt = moment.utc(item.createdAt).format('DD/MM/YYYY')
      return item
    },
    openClientDetail(row) {
      console.log('clicked row', row)
      this.clientSelected = row
      this.clientDetail = true
    },
    openDrawer() {
      this.drawerRight = true
    },
  },
  watch: {
    options: {
      handler () {
        this.getClients()
      },
      deep: true,
    },
  },
}
</script>

